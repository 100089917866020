/* eslint-disable no-unused-vars */
import * as types from "../types";
import axios from "axios";

const fetchInterventions = (
  { commit },
  { criteria, ipp_id, subject_id, page, pageSize }
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `interventions?page=${page}&ipp_id=${ipp_id}&subject_id=${subject_id}&criteria=${criteria}&limit=${pageSize}`
      )
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const fetchIntervention = ({ commit }, { interventionId }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`interventions/${interventionId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addIntervention = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post("interventions", payload, { "Content-Type": "multipart/form-data" })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const updateIntervention = ({ commit }, { interventionId, payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`interventions/${interventionId}`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const deleteIntervention = ({ commit }, { interventionId }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`interventions/${interventionId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const getInterventionData = (
  { commit },
  { ippId: ippId, subjectId: subjectId }
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`interventions/config_data?ipp_id=${ippId}&subject_id=${subjectId}`)
      .then(response => {
        commit(types.LOAD_INTERVENTIONS_CONFIG, response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default {
  fetchInterventions,
  fetchIntervention,
  addIntervention,
  updateIntervention,
  deleteIntervention,
  getInterventionData
};
