<template>
  <div style="margin: 10px 0px;">
    <h3
      v-is-disabled-for="['efector']"
      class="subtitle">
      Notas
    </h3>
    <el-row
      v-is-disabled-for="['efector']"
      :gutter="20">
      <el-col :span="16">
        <el-input
          :disabled="!editableNotes"
          v-model="ipp_notes"
          type="textarea"
          autosize
          style="margin-bottom: 20px;"
          placeholder="Ingrese sus notas" />
      </el-col>
      <el-col :span="8">
        <el-button
          :type="editableNotes ? 'danger' : 'primary'"
          size="small"
          @click="editableNotes = !editableNotes" >
          {{ editableNotes ? "Cancelar" : "Editar" }}
        </el-button>
        <el-button
          v-if="editableNotes"
          type="primary"
          size="small"
          @click="updateNotes" >
          Guardar
        </el-button>
      </el-col>
    </el-row>
    <h3 class="subtitle">Observaciones</h3>
    <el-row
      v-is-disabled-for="['efector']"
      :gutter="20">
      <el-col :span="16">
        <el-form>
          <el-form-item>
            <el-select
              v-model="newObservation"
              clearable
              filterable
              placeholder="Seleccionar observación"
              style="width: 100%;"
              @change="addObservation" >
              <el-option
                v-for="(item, index) in observationOptions"
                :key="index"
                :label="item"
                :value="item"
                :disabled="optionIncluded(item)" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
      :data="observations"
      style="100%">
      <el-table-column>
        <template slot-scope="scope">{{ scope.row }}</template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <a
            href="#"
            @click="removeObservation(scope.row)">
            <i class="el-icon-delete" />
          </a>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { clone, sortBy } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: ippActions } = namespace("ipp");
const { mapActions: listValuesActions } = namespace("admin");

export default {
  name: "IppObservations",
  props: {
    ipp: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      ipp_notes: "",
      editableNotes: false,
      newObservation: null,
      observationOptions: [],
      observations: []
    };
  },
  watch: {
    "ipp.id": function() {
      this.observations = clone(this.ipp.ipp_observation_list) || [];
    }
  },
  created() {
    this.ipp_notes = clone(this.ipp.notes);
    this.observations = clone(this.ipp.ipp_observation_list) || [];
    this.loadObservationList();
  },
  methods: {
    ...listValuesActions(["fetchListValues"]),
    ...ippActions(["updateIpp", "updateObservationsList"]),
    loadObservationList() {
      this.loading = true;
      this.fetchListValues({ list: "ipp_observations" })
        .then(response => {
          this.observationOptions = response;
        })
        .catch(error => {
          console.log("error en fetchListValues ipp_observations", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addObservation() {
      if (!this.newObservation) return;
      sortBy(this.observations.push(clone(this.newObservation)), n => n);
      this.updateObservationsList({
        ippId: this.ipp.id,
        list: this.observations
      })
        .then(() => {
          this.newObservation = null;
          this.$message.success({
            message: "Se agrego la observación",
            type: "success"
          });
        })
        .catch(error => {
          console.log("error agregando observacion", this.newObservation, error);
          this.$message.success({
            message: `No se pudo agregar la observación ${this.newObservation}`,
            type: "error"
          });
          let index = this.observations.indexOf(this.newObservation);
          this.observations.splice(index, 1);
        });
    },
    removeObservation(observation) {
      let indx = this.observations.indexOf(observation);
      this.observations.splice(indx, 1);
      this.updateObservationsList({
        ippId: this.ipp.id,
        list: this.observations
      })
        .then(() => {
          this.$message.success({
            message: `Se elimino la observacion ${observation}`,
            type: "success"
          });
        })
        .catch(() => {
          this.$message.success({
            message: `No se pudo eliminar la observacion ${observation}`,
            type: "error"
          });
          sortBy(this.observations.push(clone(observation)), n => n);
        });
    },
    optionIncluded(observation) {
      return this.observations.indexOf(observation) > -1;
    },
    updateNotes() {
      this.updateIpp({
        ippId: this.ipp.id,
        data: {
          notes: this.ipp_notes
        }
      })
        .then(response => {
          this.editableNotes = !this.editableNotes;
          this.ipp_notes = response.ipp_case.notes;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
hr {
  margin: 20px 0px;
}
.subtitle {
  background: #f1f1f1;
  padding: 10px 5px;
  text-align: left;
  margin-top: 0px;
}
.assigend-area {
  margin-bottom: 25px;
}
.ipp-observation {
  margin: 10px 0px !important;
  padding-bottom: 10px;
  border-bottom: solid 1px #eee;
}
.ipp-observation .el-col.control {
  text-align: right !important;
}
</style>
