<template>
  <div
    id="organizations-data"
    class="page">
    <div class="page-header">
      <h2>
        Red
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        Organizaciones
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        Nuevo
      </h2>
    </div>
    <div
      class="page-content"
      style="padding: 20px;">
      <organization-form
        :organization="organization"
        @save="submit"
        @reset="reset" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
import OrganizationForm from "./OrganizationForm";
const { mapActions: organizationActions } = namespace("organization");

export default {
  name: "NewOrganization",
  components: { OrganizationForm },
  data() {
    return {
      organization: {}
    };
  },
  methods: {
    ...organizationActions(["addOrganization"]),
    submit() {
      let payload = {
        name: this.organization.name,
        leading_entity: this.organization.leading_entity,
        department_id: this.organization.department_id,
        city_id: this.organization.city_id,
        address: this.organization.address,
        coordinates: this.organization.coordinates,
        phone: this.organization.phone,
        email: this.organization.email,
        website: this.organization.website,
        contact_name: this.organization.contact_name,
        contact_mode: this.organization.contact_mode,
        resource_name: this.organization.resource_name,
        program_duration: this.organization.program_duration,
        program_duration_type: this.organization.program_duration_type,
        program_start_periodicity: this.organization.program_start_periodicity,
        accepts_externals: this.organization.accepts_externals,
        accepts_minors: this.organization.accepts_minors,
        vacants: this.organization.vacants,
        activity_address: this.organization.activity_address,
        activity_coordinates: this.organization.activity_coordinates,
        resource_types: this.organization.resource_types,
        scopes: this.organization.scopes,
        descriptions: this.organization.descriptions,
        characteristics: this.organization.characteristics,
        aims: this.organization.aims,
        keywords: this.organization.keywords,
        entry_conditions: this.organization.entry_conditions,
        entry_modes: this.organization.entry_modes
      };
      this.loading = true;
      this.addOrganization(payload)
        .then(response => {
          this.$message.success("Se creó la organización");
          this.$router.push({
            name: "organization",
            params: { id: response.organization.id }
          });
        })
        .catch(error => {
          console.log("error grabando organización", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.organization = {};
    }
  }
};
</script>

<style>
#organizations-data {
  background: #fff;
  height: 100%;
}

.data {
  padding: 0px 20px;
}
</style>
