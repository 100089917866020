<template>
  <div class="page">
    <div class="page-header">
      <div style="display: flex; justify-content: space-between; align-items: center;;">
        <h2>Administración</h2>
        <div style="float: right; font-size: 20px;">
          <span style="margin: 0px 10px;">
            <router-link :to="{ name: 'adminUsers' }"><i class="el-icon-user"></i> Usuarios</router-link>
          </span>
          <span style="margin: 0px 10px;">
            <router-link :to="{ name: 'adminConfig' }"><i class="el-icon-s-tools"></i> Configuración</router-link>
          </span>
        </div>
      </div>
    </div>
    <div
      class="page-content"
      style="padding: 0px;bottom: 0px;">
      <div
        lass="scroll-inner"
        style="padding: 10px;">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin"
};
</script>

<style scoped>
.page-header a.router-link-exact-active {
  color: #409EFF;
}

.scroll-inner {
  margin-top: 4em;
  overflow: scroll;
}
.el-tab-pane {
  padding: 0px 40px;
}
</style>
