<template>
  <div id="wrapper">
    <div class="title">
      <img
        style="margin-top: 70px;"
        src="~@/assets/images/logo.png" >
      <h2>Cambiar contraseña</h2>
    </div>
    <div class="login-container">
      <div v-if="hasError">
        <el-alert
          :closable="false"
          :center="true"
          :title="message"
          type="error"
          show-icon />
        <br >
      </div>
      <el-form
        ref="resetPasswordForm"
        :model="credentials">
        <el-form-item>
          <el-input
            v-model="credentials.password"
            name="password"
            type="password"
            placeholder="contraseña"
            prefix-icon="el-icon-message" />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="credentials.password_confirmation"
            name="password_confirmation"
            type="password"
            placeholder="confirme la contraseña"
            prefix-icon="el-icon-message" />
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            :disabled="!isValidData"
            type="primary"
            style="width: 100%;"
            @click.native.prevent="doResetPassword()" >
            Generar nueva contraseña
          </el-button>
        </el-form-item>
        <el-button
          type="secondary"
          style="width: 100%;"
          @click.native.prevent="goToLogin()" >
          Iniciar sesión
        </el-button>
      </el-form>
      <hr >
      <div style="text-align: center; clear: both;">

        <div>
          <img
            src="~@/assets/images/logo_funrepar.png"
            height="40" >
        </div>
        <div>
          <img
            src="~@/assets/images/logo_morelabs.png"
            height="30" >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: authActions } = namespace("auth");

export default {
  name: "ResetPassword",
  data() {
    return {
      loading: false,
      hasError: false,
      message: null,
      credentials: {
        uuid: "",
        password: "",
        password_confirmation: ""
      }
    };
  },
  created() {
    this.credentials.uuid = this.$route.params.uuid;
  },
  computed: {
    isValidData() {
      return this.credentials.password && this.credentials.password_confirmation;
    }
  },
  methods: {
    ...authActions(["resetPassword"]),
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    doResetPassword() {
      this.loading = true;
      this.resetPassword(this.credentials)
        .then(response => {
          this.hasError = false;
          this.$notify.success({
            title: "Contraseña actualizada",
            message: "Inicie sesión con su nueva contraseña",
            position: "bottom-left"
          });
          this.$router.push({ name: "login" });
        })
        .catch(error => {
          if (error.message) {
            this.message = error.message;
          } else {
            this.message = error.authentication;
          }
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
#wrapper {
  height: 100%;
  width: 30%;
  text-align: center;
  margin: auto;
}
.login-container {
  margin: 30px auto;
}
hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 40px 0px;
}
.debug {
  margin: 30px auto;
  border-top: solid 1px #f1f1f1;
  padding-top: 30px;
  text-align: center;
  font-size: 12px;
  color: #999;
}
.title {
  margin: 30px auto;
}
.title h1 {
  margin: 10px 0px;
}
.title img {
  width: 180px;
  margin: auto;
}
</style>
