/* eslint-disable no-unused-vars */
import axios from "axios";
import * as types from "../types";

// Actions against BACKEND
const loadIppData = ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`ipp_cases/config_data`)
      .then(response => {
        commit(types.LOAD_IPP_DATA, response.data);
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const checkDNI = ({ commit, state }, { dni }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("people/validate_user", { dni: dni })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const fetchIpps = (
  { commit },
  { field, criteria, page, pageSize, active, assigned, own }
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `ipp_cases?page=${page}&field=${field}&criteria=${criteria}&limit=${pageSize}&active=${active}&assigned=${assigned}&own=${own}`
      )
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const fetchIpp = ({ commit }, { ippId }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`ipp_cases/${ippId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addIpp = ({ commit }, { ipp }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("ipp_cases", { ipp_case: ipp })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const updateIpp = ({ commit }, { ippId, data }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`ipp_cases/${ippId}`, { ipp_case: data })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const listPossibleResponsables = ({ commit }, { ippId, criteria }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`ipp_cases/${ippId}/responsables/possible?criteria=${criteria}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addResponsable = ({ commit }, { ippId, userId }) => {
  console.log("Add action", ippId, userId);
  return new Promise((resolve, reject) => {
    axios
      .post(`ipp_cases/${ippId}/responsables`, {
        ipp_case_responsable: { responsable_id: userId }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const removeResponsable = ({ commit }, { ippId, responsableId }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`ipp_cases/${ippId}/responsables/${responsableId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const updateSubjectIpp = ({ commit }, { ippId, subject }) => {
  return new Promise((resolve, reject) => {
    if (subject.person_id) {
      axios
        .patch(`ipp_cases/${ippId}`, {
          ipp_case: { subjects_attributes: [subject] }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(`From actions ${error}`);
          reject(error);
        });
    } else {
      reject("Antes de guardar, hay que agregar el sujeto.");
    }
  });
};

const createPerson = ({ commit }, { person }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("people", { person: person })
      .then(response => {
        resolve(response.data.person);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// Actions against VUEX
const addPerson = ({ commit }, { person, role, minor }) => {
  return new Promise((resolve, reject) => {
    if (person.id) {
      person.minor = minor;
      commit(types.ADD_PERSON, { person: person, role: role });
      resolve(true);
    } else {
      axios
        .post("people", { person: person })
        .then(response => {
          person.minor = minor;
          commit(types.ADD_PERSON, {
            person: response.data.person,
            role: role
          });
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    }
  });
};

const removePerson = ({ commit }, { personId, role }) => {
  return new Promise((resolve) => {
    commit(types.REMOVE_PERSON, { personId: personId, role: role });
    resolve(true);
  });
};

const updateObservationsList = ({ commit }, { ippId, list }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`ipp_cases/${ippId}/observations`, { observations: list })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addPoliceStation = ({ commit }, { police_station }) => {
  return new Promise((resolve) => {
    commit(types.ADD_POLICE_STATION, police_station);
    resolve(true);
  });
};

const removePoliceStation = ({ commit }) => {
  return new Promise((resolve) => {
    commit(types.REMOVE_POLICE_STATION);
    resolve(true);
  });
};

const addDefinition = ({ commit }, { definition }) => {
  return new Promise((resolve) => {
    commit(types.ADD_DEFINITION, definition);
    resolve(true);
  });
};

const removeDefintion = ({ commit }) => {
  return new Promise((resolve) => {
    commit(types.REMOVE_DEFINITION);
    resolve(true);
  });
};

const addCrime = ({ commit }, { crime }) => {
  return new Promise((resolve) => {
    commit(types.ADD_CRIME, crime);
    resolve(true);
  });
};

const removeCrime = ({ commit }, { crimeId }) => {
  return new Promise((resolve) => {
    commit(types.REMOVE_CRIME, crimeId);
    resolve(true);
  });
};

const addObservation = ({ commit }, { observation }) => {
  return new Promise((resolve) => {
    commit(types.ADD_OBSERVATION, observation);
    resolve(true);
  });
};

const removeObservation = ({ commit }, { observation }) => {
  return new Promise((resolve) => {
    commit(types.REMOVE_OBSERVATION, observation);
    resolve(true);
  });
};

export default {
  loadIppData,
  checkDNI,
  fetchIpps,
  fetchIpp,
  addIpp,
  updateIpp,
  updateObservationsList,
  updateSubjectIpp,
  addPerson,
  createPerson,
  removePerson,
  listPossibleResponsables,
  addResponsable,
  removeResponsable,
  addCrime,
  removeCrime,
  addPoliceStation,
  removePoliceStation,
  addObservation,
  removeObservation,
  addDefinition,
  removeDefintion
};
