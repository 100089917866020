<template>
  <div style="margin: 10px 0px;">
    <h3 class="subtitle">
      {{ fullname }}
      <div
        v-is-disabled-for="['efector']"
        style="float: right">
        <el-button
          size="mini"
          type="info"
          @click="updateEnabled = !updateEnabled"
          style="margin-right: 10px">
          {{ updateEnabled ? "Terminar" : "Editar" }}
        </el-button>
        <router-link :to="{ name: 'subject', params: { id: victimizer.id } }">
          <el-button
            size="mini"
            type="primary"
            style="margin-right: 10px">
            Expediente
          </el-button>
        </router-link>
        <el-button
          type="primary"
          size="mini"
          @click="openModal()"
          style="margin-right: 10px">
          Datos Personales
        </el-button>
        <router-link :to="{ name: 'derivations', params: { id: victimizer.id } }">
          <el-button
            size="mini"
            type="primary"
            style="margin-right: 10px">
            Derivaciones
          </el-button>
        </router-link>
        <router-link
          :to="{
            name: 'interventions',
            params: {
              ipp_id: ipp.id,
              subject_id: victimizer.id,
              ipp_number: ipp.ipp_number
            }
          }"
        >
          <el-button
            size="mini"
            type="primary">
            Intervenciones
          </el-button>
        </router-link>
      </div>
    </h3>
    <el-row :gutter="20">
      <el-col :span="20">
        <div class="subject-row">
          <div class="label">DNI:</div>
          <div class="value">
            {{ victimizer.person.document_number || "-----" }}
          </div>
        </div>
        <div class="subject-row">
          <div class="label">Fecha Nacimiento:</div>
          <div class="value">
            {{ victimizer.person.birth_date | moment("DD [de] MMMM, YYYY") }}
          </div>
        </div>
        <div class="subject-row">
          <div class="label">Direccion:</div>
          <div class="value">{{ victimizer.person.address || "-----" }}</div>
        </div>
        <div style="margin-bottom: 10px;">
          <div
            v-if="!updateEnabled"
            class="subject-row">
            <div class="label">Fecha Indagatoria:</div>
            <div class="value">
              <div v-if="victimizer.inquiry_date">
                {{ victimizer.inquiry_date | moment("dddd DD [de] MMMM, YYYY") }}
              </div>
              <div v-else>Sin Fecha definida</div>
            </div>
          </div>
          <div
            v-else
            class="subject-row">
            <div class="label">Fecha Indagatoria:</div>
            <div class="value">
              <el-date-picker
                v-model="victimizer.inquiry_date"
                :clearable="true"
                :format="dateFormat"
                style="width: 100%;"
                name="event_date"
                placeholder="Fecha de indagatoria"
                @change="update()" />
            </div>
          </div>
        </div>
        <div class="subject-row">
          <div class="label">Tipo Victimario</div>
          <div class="value">
            <el-checkbox
              v-model="victimizer.legal"
              :disabled="!updateEnabled"
              type="primary"
              @change="update()" >
              Legal
            </el-checkbox>
            <el-checkbox
              v-model="victimizer.restorative"
              :disabled="!updateEnabled"
              type="primary"
              @change="update()" >
              Restaurativo
            </el-checkbox>
            <el-checkbox
              v-model="victimizer.conflictual"
              :disabled="!updateEnabled"
              type="primary"
              @change="update()" >
              Conflictual
            </el-checkbox>
          </div>
        </div>
        <div
          v-is-disabled-for="['efector']"
          class="subject-row">
          <div class="label">Derivaciones vigentes</div>
          <div
            v-if="victimizer.derivations.length > 0"
            class="value">
            <ul
              v-for="d in victimizer.derivations"
              :key="d.id">
              <li>
                {{ d.type.toUpperCase() }} - {{ d.description }} - Proximo
                control: {{ d.next_control || "S/D" }}
              </li>
            </ul>
          </div>
          <div
            v-else
            class="value">
            No tiene derivaciones activas
          </div>
        </div>
      </el-col>
      <el-col
        v-is-disabled-for="['efector']"
        :span="4">
        <VueSvgGauge
          :start-angle="-120"
          :end-angle="120"
          :value="victimizer.file_completion_pct"
          :separator-step="0"
          :scale-interval="10"
          :inner-radius="60" >
          <div class="inner-text">
            <span>
              <b>{{ victimizer.file_completion_pct }}</b> %
            </span>
          </div>
        </VueSvgGauge>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { clone } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: subjectActions } = namespace("subject");

export default {
  name: "Victimizer",
  props: {
    victimizer: {
      type: Object,
      required: true
    },
    ipp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      person: {},
      isOpen: false,
      dateFormat: "dddd dd, MMMM yyyy",
      updateEnabled: false
    };
  },
  computed: {
    fullname() {
      return `${this.victimizer.person.lastname},
        ${this.victimizer.person.firstname}`;
    }
  },
  created() {
    this.person = clone(this.victimizer.person);
  },
  methods: {
    ...subjectActions(["updateSubject"]),
    update() {
      let payload = {
        inquiry_date: this.victimizer.inquiry_date,
        legal: this.victimizer.legal,
        restorative: this.victimizer.restorative,
        conflictual: this.victimizer.conflictual
      };
      this.updateSubject({ subjectId: this.victimizer.id, payload: payload })
        .then(response => {
          this.$emit("update-victimizer", response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    openModal() {
      this.$emit("open-modal");
    }
  }
};
</script>

<style lang="css" scoped>
.subtitle {
  background: #f1f1f1;
  padding: 10px 5px;
  text-align: left;
  margin-top: 0px;
}
.subject-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0px;
  .label {
    flex: 1;
  }
  .value {
    flex: 3;
  }
}
</style>
