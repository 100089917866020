<template>
  <div style="margin: 10px 0px;">
    <h3 class="subtitle">
      {{ fullname }}
      <div
        v-is-disabled-for="['efector']"
        style="float: right; font-size: 1rem;" >
        <el-button
          size="mini"
          type="info"
          style="margin-right: 10px"
          @click="updateEnabled = !updateEnabled" >
          {{ updateEnabled ? "Terminar" : "Editar" }}
        </el-button>
        <router-link :to="{ name: 'subject', params: { id: victim.id } }">
          <el-button
            size="mini"
            type="primary"
            style="margin-right: 10px">
            Expediente
          </el-button>
        </router-link>
        <el-button
          type="primary"
          size="mini"
          @click="openModal()"
          style="margin-right: 10px">
          Datos Personales
        </el-button>
        <router-link
          :to="{
            name: 'derivations',
            params: {
              id: victim.id
            }
          }"
        >
          <el-button
            size="mini"
            type="primary"
            style="margin-right: 10px">
            Derivaciones
          </el-button>
        </router-link>
        <router-link
          :to="{
            name: 'interventions',
            params: {
              ipp_id: ipp.id,
              subject_id: victim.id,
              ipp_number: ipp.ipp_number
            }
          }"
        >
          <el-button
            size="mini"
            type="primary">
            Intervenciones
          </el-button>
        </router-link>
      </div>
    </h3>
    <el-row :gutter="20">
      <el-col :span="20">
        <div class="subject-row">
          <div class="label">DNI:</div>
          <div class="value">
            {{ victim.person.document_number || "-----" }}
          </div>
        </div>
        <div class="subject-row">
          <div class="label">Fecha Nacimiento:</div>
          <div class="value">
            {{ victim.person.birth_date | moment("DD [de] MMMM, YYYY") }}
          </div>
        </div>
        <div class="subject-row">
          <div class="label">Direccion:</div>
          <div class="value">{{ victim.person.address || "-----" }}</div>
        </div>
        <div class="subject-row">
          <div class="label">Tipo Victima</div>
          <div class="value">
            <el-checkbox
              v-model="victim.legal"
              :disabled="!updateEnabled"
              type="primary"
              @change="update()" >
              Legal
            </el-checkbox>
            <el-checkbox
              v-model="victim.restorative"
              :disabled="!updateEnabled"
              type="primary"
              @change="update()" >
              Restaurativo
            </el-checkbox>
            <el-checkbox
              v-model="victim.conflictual"
              :disabled="!updateEnabled"
              type="primary"
              @change="update()" >
              Conflictual
            </el-checkbox>
          </div>
        </div>
        <div
          v-is-disabled-for="['efector']"
          class="subject-row">
          <div class="label">Derivaciones vigentes</div>
          <div
            v-if="victim.derivations.length > 0"
            class="value">
            <ul
              v-for="d in victim.derivations"
              :key="d.id">
              <li>
                {{ d.type.toUpperCase() }} - {{ d.description }} - Proximo
                control: {{ d.next_control || "S/D" }}
              </li>
            </ul>
          </div>
          <div
            v-else
            class="value">
            No tiene derivaciones activas
          </div>
        </div>
      </el-col>
      <el-col
        v-is-disabled-for="['efector']"
        :span="4">
        <VueSvgGauge
          :start-angle="-120"
          :end-angle="120"
          :value="victim.file_completion_pct"
          :separator-step="0"
          :scale-interval="10"
          :inner-radius="60" >
          <div class="inner-text">
            <span>
              <b>{{ victim.file_completion_pct }}</b> %
            </span>
          </div>
        </VueSvgGauge>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: subjectActions } = namespace("subject");
export default {
  name: "Victim",
  props: {
    victim: {
      type: Object,
      required: true
    },
    ipp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updateEnabled: false
    };
  },
  computed: {
    fullname() {
      return `${this.victim.person.lastname},
        ${this.victim.person.firstname}`;
    }
  },
  methods: {
    ...subjectActions(["updateSubject"]),
    update() {
      let payload = {
        legal: this.victim.legal,
        restorative: this.victim.restorative,
        conflictual: this.victim.conflictual
      };
      this.updateSubject({ subjectId: this.victim.id, payload: payload })
        .then(response => {
          this.$emit("update-victim", response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    openModal() {
      this.$emit("open-modal", this.victim);
    }
  }
};
</script>

<style lang="css" scoped>
.subtitle {
  background: #f1f1f1;
  padding: 10px 5px;
  text-align: left;
  margin-top: 0px;
}
.subject-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0px;
}
.subject-row .label {
  flex: 1;
}
.subject-row .value {
  flex: 3;
}

</style>
