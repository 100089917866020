<template>
  <div>
    <el-container>
      <el-aside
        :class="collapsed ? 'collapsed' : ''"
        class="main-side-menu"
        width="200px">
        <main-side-menu
          :user="user"
          @open-search-modal="openSearchModal()"
          @confirm-logout="closeSession()"
          @toggle-collapse="value => toggleCollapse(value)" />
      </el-aside>
      <el-main>
        <transition
          name="slide-right"
          mode="out-in">
          <router-view />
        </transition>
        <main-search ref="search" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import MainSearch from "@/components/Shared/MainSearch";
import MainSideMenu from "@/components/Layout/MainSideMenu";

import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: authActions, mapGetters: authGetters } = namespace("auth");

export default {
  name: "Layout",
  components: {
    MainSearch,
    MainSideMenu
  },
  data() {
    return {
      loading: false,
      confirmLogut: false,
      collapsed: false,
      showModal: false,
      errorUpdate: false,
      installDialogModal: false,
      waitingInstall: false,
      process: process.env
    };
  },
  computed: {
    ...authGetters(["user"])
  },
  created() {
    let u = JSON.parse(localStorage.getItem("user"));
    if (!u.jurisdiction) {
      this.setDefaultModal = true;
    }
  },
  methods: {
    ...authActions(["logout", "setJurisdiction"]),
    closeSession() {
      this.logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },
    toggleCollapse(value) {
      this.collapsed = value;
    },
    openSearchModal() {
      let modal = this.$refs.search;
      modal.openSearch();
    }
  }
};
</script>

<style lang="css">
.image {
  margin: 0px auto;
  text-align: center;
}
.el-container {
  height: 100vh;
}

.el-main {
  padding: 0px;
  overflow-x: hidden;
}
.page {
  height: 100%;
  position: relative;
}
.page .page-header {
  top: 0px;
  left: 0px;
  height: 3em;
  line-height: 2em;
  border-bottom: solid 1px #eee;
  position: absolute;
  z-index: 100;
  background: #fff;
  width: 100%;
  padding: 10px 0px;
}

.page .page-footer {
  bottom: 1px;
  left: 0px;
  position: absolute;
  width: 100%;
  z-index: 100;
  background: #fff;
  border-top: solid 1px #eee;
  padding: 13px 20px;
}
.page .page-footer .el-pagination__rightwrapper {
  margin-right: 80px;
}
.page .page-header h2 {
  margin: 10px 0px;
}
.page .page-header .sep {
  color: #999;
  margin: 0px 10px;
}
.page .page-header .controls {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

.page .page-content .page-form .el-form-item {
  margin-bottom: 0px;
}
.page .page-content {
  position: absolute;
  top: 5em;
  bottom: 3em;
  left: 0px;
  right: 0px;
  overflow: auto;
}

.page .page-content.no-bottom {
  bottom: 0em;
}

.el-popover {
  z-index: 20000;
}

.pac-container {
  z-index: 100000 !important;
}
</style>
