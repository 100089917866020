<template>
  <div
    v-if="intervention.id"
    class="page">
    <div class="page-header">
      <router-link
        :to="{ name: 'interventions' }"
        style="float: left; margin: 5px 20px 5px 0px; font-size: 20px;">
        <i class="el-icon-back"></i>
      </router-link>
      <div
        v-is-disabled-for="['efector']"
        class="controls">
        <router-link
          :to="{
            name: 'editIntervention',
            params: {
              id: intervention.id,
              ipp_id: intervention.ipp_case_id,
              subject_id: intervention.subject_id,
              ipp_number: intervention.ipp_case_number
            }
          }"
        >
          <el-button type="warning">Editar</el-button>
        </router-link>
        <el-button
          type="danger"
          @click="destroyIntervention()">
          Eliminar
        </el-button>
      </div>
      <h2>Intervención #{{ intervention.id }}</h2>
    </div>
    <div class="page-content">
      <el-row
        :gutter="20"
        class="ipp-row">
        <el-col :span="24">
          <h3><i class="el-icon-folder-checked"></i> Informacion</h3>
        </el-col>
        <el-col :span="6">
          <div>
            <div class="ipp-label">Nro IPP</div>
            <div class="ipp-data">
              <router-link :to="{ name: 'ipp', params: { id: intervention.ipp_case_id } }">
                {{ intervention.ipp_case_number }}
                <i
                  class="el-icon-link"
                  style="font-weight: bold; font-size: 1.1rem;">
                </i>
              </router-link>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <div class="ipp-label">Sujeto</div>
            <div class="ipp-data">{{ intervention.subject_data }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <div class="ipp-label">Es Publica?</div>
            <div class="ipp-data">{{ intervention.public ? "Si" : "No" }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="ipp-row">
        <el-col :span="6">
          <div>
            <div class="ipp-label">Fecha</div>
            <div class="ipp-data">
              {{ intervention.intervention_date | moment("ddd DD MMM YYYY") | capitalize }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <div class="ipp-label">Hora</div>
            <div class="ipp-data">
              {{ intervention.start_hour }} a {{ intervention.end_hour }}
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <div class="ipp-label">Lugar</div>
            <div class="ipp-data">{{ intervention.place | capitalize }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="ipp-row">
        <el-col :span="6">
          <div>
            <div class="ipp-label">Flujo</div>
            <div class="ipp-data">{{ intervention.flow_name }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <div class="ipp-label">Etapa</div>
            <div class="ipp-data">{{ intervention.stage_name }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <div class="ipp-label">Tipo</div>
            <div class="ipp-data">{{ intervention.intervention_type }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <div class="ipp-label">Resultado</div>
            <div class="ipp-data">{{ intervention.result }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="ipp-row">
        <el-col :span="24">
          <h3><i class="el-icon-user"></i> Participantes</h3>
        </el-col>
        <el-col :span="24">
          <ul style="list-style: none; padding: 0px; margin: 0px;">
            <li
              v-for="(participant, index) in intervention.raw_participants"
              :key="index"
              class="participant-row">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <span>{{ participant.name }}</span>
                <span>{{ participant.role }}</span>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row
        v-is-disabled-for="['efector']"
        :gutter="20">
        <el-col :span="24">
          <h3><i class="el-icon-chat-line-square"></i> Observaciones</h3>
          <div>
            {{ intervention.observations }}
          </div>
        </el-col>
      </el-row>
      <hr >
      <el-row :gutter="20">
        <el-col :span="24">
          <h3><i class="el-icon-document"></i> Documento</h3>
          <el-row
            v-if="intervention.has_document"
            style="display: flex; align-items: center;" >
            <el-col :span="16">
              <span style="font-size: 1.2rem; color: #888; border: solid 1px #eee; padding: 8px 10px;">
                {{ intervention.document_metadata.filename }}
              </span>
            </el-col>
            <el-col
              :span="8"
              style="text-align: right;">
              <a
                :href="intervention.document_url"
                class="el-button el-button--info">
                <i class="el-icon-paperclip"></i>
                Bajar Documento ({{ intervention.document_metadata.size }})
              </a>
            </el-col>
          </el-row>
          <el-row v-else>
            <el-col :span="24">
              No hay documento en esta intervencion
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const {
  mapActions: interventionActions
} = namespace("intervention");

export default {
  name: "Intervention",
  data() {
    return {
      loading: false,
      ipp_id: null,
      ipp_number: null,
      intervention: {}
    };
  },
  created() {
    this.loadIntervention();
  },
  methods: {
    ...interventionActions(["fetchIntervention", "deleteIntervention"]),
    loadIntervention() {
      this.loading = true;
      this.fetchIntervention({ interventionId: this.$route.params.id })
        .then(response => {
          this.intervention = response.intervention;
          this.ipp_id = this.intervention.ipp_case_id;
          this.ipp_number = this.intervention.ipp_case_number;
        })
        .catch(error => {
          console.log("error en fetchIntervention", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    destroyIntervention() {
      console.log("entro en destroyIntervention");
      this.loading = true;
      this.deleteIntervention({ interventionId: this.$route.params.id })
        .then(() => {
          this.$router.push({
            name: "interventions",
            params: {
              ipp_id: this.ipp_id,
              ipp_number: this.ipp_number
            }
          });
        })
        .catch(error => {
          console.log("error en destroyIntervention", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="css">
#interventions-data {
  background: #fff;
  height: 100%;
}

.ipp-row {
  padding: 15px 0px;
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
  .ipp-label {
    font-size: 0.8rem;
    color: teal;
    margin-bottom: 10px;
  }
  .ipp-data {
    font-size: 1.1rem;
    .no-data {
      color: #888;
    }
  }
}
.participant-row {
  border-bottom: dashed 1px #eee;
  padding: 15px 0px 15px;
  &:last-child {
    border-bottom: none;
  }
}
</style>
