<template>
  <div class="page">
    <div class="page-header">
      <router-link
        :to="{
          name: 'interventions',
          params: {
            ipp_id: intervention.ipp_case_id,
            ipp_number: intervention.ipp_case_number
          }
        }"
        style="float: left; margin: 5px 20px 5px 0px; font-size: 20px;">
        <i class="el-icon-back"></i>
      </router-link>
      <h2>
        Nueva Intervención
      </h2>
    </div>
    <div
      class="page-content"
      style="padding: 20px;">
      <intervention-form
        :intervention-data="intervention"
        @reloadConfig="value => reloadConfig(value)"
        @save="data => submit(data)"
        @cancel="cancel" />
    </div>
  </div>
</template>

<script>
import { forEach } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
import InterventionForm from "./InterventionForm";
const { mapActions: interventionActions } = namespace("intervention");

export default {
  name: "NewIntervention",
  components: { InterventionForm },
  data() {
    return {
      intervention: {
        ipp_case_number: this.$route.params.ipp_number,
        ipp_case_id: this.$route.params.ipp_id,
        subject_id: this.$route.params.subject_id,
        stage: null,
        intervention_type: null,
        result: null,
        observations: null,
        intervention_date: null,
        start_hour: null,
        end_hour: null,
        place: null,
        public: false,
        flow_step_id: null,
        participants: []
      }
    };
  },
  created() {
    this.loadInterventionData();
  },
  methods: {
    ...interventionActions(["addIntervention", "getInterventionData"]),
    submit(data) {
      this.loading = true;
      let params = new FormData();
      forEach(data, (value, key) => {
        params.append(
          `intervention[${key}]`,
          data[key] == false ? data[key] : data[key] || ""
        );
      });
      this.addIntervention(params)
        .then(response => {
          this.$message.success("Se creó la intervención");
          this.$router.push({
            name: "intervention",
            params: { id: response.intervention.id }
          });
        })
        .catch(error => {
          console.log("error grabando intervención", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$router.push({
        name: "interventions",
        params: {
          ipp_id: this.intervention.ipp_case_id,
          subject_id: this.intervention.subject_id,
          ipp_number: this.intervention.ipp_case_number
        }
      });
    },
    reloadConfig(subjectId) {
      this.loadData(this.intervention.ipp_case_id, subjectId);
    },
    loadInterventionData() {
      this.loadData(this.$route.params.ipp_id, this.$route.params.subject_id);
    },
    loadData(ippId, subjectId) {
      this.getInterventionData({
        ippId: ippId,
        subjectId: subjectId
      }).then(response => {
        this.neededData = response.data;
      });
    }
  }
};
</script>

<style>
#interventions-data {
  background: #fff;
  height: 100%;
}

.data {
  padding: 0px 20px;
}
</style>
