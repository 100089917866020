<template>
  <el-form
    ref="form"
    :model="derivation"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Tipo de derivación">
          <el-select
            v-model="derivation.type"
            style="width: 100%"
            placeholder="Seleccione"
          >
            <el-option
              v-for="(value, key) in typeOptions"
              :key="key"
              :label="value"
              :value="key"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Tipo de miembro de la Red">
          <el-select
            v-model="derivation.derivable_type"
            style="width: 100%"
            placeholder="Seleccione"
            @change="loadDerivables"
          >
            <el-option
              v-for="(value, key) in derivableTypeOptions"
              :key="key"
              :label="value"
              :value="key"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Miembro de la Red">
          <el-select
            v-model="derivation.derivable_id"
            filterable
            style="width: 100%"
            placeholder="Seleccione"
          >
            <el-option
              v-for="item in derivables"
              :key="item.id"
              :label="item.full_name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Estado">
          <el-select
            v-model="derivation.state"
            style="width: 100%"
            placeholder="Seleccione"
          >
            <el-option
              v-for="(value, key) in stateOptions"
              :key="key"
              :label="value"
              :value="key"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Fecha de inicio">
          <el-date-picker
            v-model="derivation.date_from"
            :format="dateFormat"
            style="width: 100%;"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Fecha de proximo control">
          <el-date-picker
            v-model="derivation.next_control"
            :format="dateFormat"
            style="width: 100%;"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="Motivo">
          <el-input v-model="derivation.reason" />
        </el-form-item>
      </el-col>
    </el-row>
    <hr >
    <el-form-item>
      <el-button
        type="success" 
        @click="onSubmit">Guardar</el-button>
      <el-button
        type="danger" 
        @click="onReset">Cancelar</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: organizationActions } = namespace("organization");
const { mapActions: referentActions } = namespace("referent");
const { mapActions: programActions } = namespace("program");

export default {
  name: "DerivationForm",
  props: {
    derivation: { type: Object, required: true }
  },
  data() {
    return {
      dateFormat: "dddd dd, MMMM yyyy",
      stateOptions: {
        draft: "Borrador",
        started: "En progreso",
        finished: "Finalizada"
      },
      typeOptions: {
        derivation: "Derivación",
        articulation: "Articulación",
        coordination: "Coordinación"
      },
      derivableTypeOptions: {
        Referent: "Referente",
        Program: "Programa",
        Organization: "Organización"
      },
      derivables: []
    };
  },
  created() {
    this.loadDerivables(this.derivation.derivable_type);
  },
  methods: {
    ...organizationActions(["fetchOrganizations"]),
    ...referentActions(["fetchReferents"]),
    ...programActions(["fetchPrograms"]),
    loadDerivables(newDerivableType) {
      if (!newDerivableType) return;
      this.loading = true;
      if (newDerivableType == "Organization") {
        this.fetchOrganizations({
          criteria: "",
          page: 1,
          pageSize: 999999
        })
          .then(response => {
            this.derivables = response.organizations;
          })
          .catch(error => {
            console.log("error en fetchOrganizations", error);
          });
      } else if (newDerivableType == "Referent") {
        this.fetchReferents({
          criteria: "",
          page: 1,
          pageSize: 999999
        })
          .then(response => {
            this.derivables = response.referents;
          })
          .catch(error => {
            console.log("error en fetchReferents", error);
          });
      } else if (newDerivableType == "Program") {
        this.fetchPrograms({
          criteria: "",
          page: 1,
          pageSize: 999999
        })
          .then(response => {
            this.derivables = response.programs;
          })
          .catch(error => {
            console.log("error en fetchPrograms", error);
          });
      }
      this.loading = false;
    },
    onSubmit() {
      this.$emit("save");
    },
    onReset() {
      this.$emit("reset");
    }
  }
};
</script>
