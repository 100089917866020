import getters from "./getters";
import actions from "./actions";
import * as types from "../types";

const state = {
  interventions: [],
  config: {}
};

const mutations = {
  [types.LOAD_INTERVENTIONS_SUCCESS](state, data) {
    state.interventions = data.interventions;
  },
  [types.LOAD_INTERVENTIONS_ERROR](state) {
    state.interventions = [];
  },
  [types.ADD_INTERVENTION_SUCCESS](state, data) {
    state.interventions.push(data.intervention);
  },
  [types.UPDATE_INTERVENTION_SUCCESS](state, data) {
    let current = state.interventions.find(intervention => {
      return intervention.id === data.intervention.id;
    });
    let currentIndex = state.interventions.indexOf(current);
    state.interventions.splice(currentIndex, 1, data.intervention);
  },
  [types.LOAD_INTERVENTIONS_CONFIG](state, data) {
    state.config = data;
  },
  [types.DELETE_INTERVENTION_SUCCESS](state, data) {
    let current = state.intervention.find(intervention => {
      return intervention.id === data.intervention.id;
    });
    let currentIndex = state.intervention.indexOf(current);
    state.intervention.splice(currentIndex, 1);
  }
};

export default { state, mutations, getters, actions, namespaced: true };
