<template>
  <div
    v-loading="!subject.id"
    class="page subject" >
    <div v-if="subject.id">
      <div class="page-header" style="padding: 10px 0px;">
        <h2>
          <router-link :to="{ name: 'ipp', params: { id: subject.ipp_case_id } }">
            IPP - Nro. {{ subject.ipp_case.ipp_number }}
          </router-link>
          {{ fullname }}
          <span class="header-notification">
            {{ formatDate(subject.inquiry_date) }}
          </span>
        </h2>
      </div>
      <div class="page-content no-bottom">
        <div class="list">
          <div class="ipp-content">
            <el-tabs
              v-model="activeTabName"
              :stretch="true">
              <el-tab-pane
                v-for="(category_data,
                        category_name,
                        category_index) in subject.file_data"
                :key="category_index"
                :label="category_name"
                :name="category_name"
              >
                <div
                  v-for="(subcategory_data,
                          subcategory_name,
                          subcategory_index) in category_data"
                  :key="subcategory_index"
                >
                  <h3>
                    {{
                      subcategory_name.trim() == ""
                        ? category_name
                        : subcategory_name
                    }}
                  </h3>
                  <div
                    v-for="(item, item_key) in subcategory_data.questions"
                    :key="item_key"
                    class="question_slot"
                  >
                    <question
                      :ref="`question_${item.code}`"
                      :class="`question_${item.code}`"
                      :show="item.show"
                      :question="item.question"
                      :answer="item.answer"
                      :editable="subject.editable"
                      :subject-id="subject.id"
                      :ipp-case-id="subject.ipp_case_id"
                      @toggle="toggleQuestionComponent($event)"
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filter, map } from "lodash";
import Question from "./Question";
import * as moment from "moment";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: subjectActions } = namespace("subject");

export default {
  name: "Subject",
  components: { Question },
  data() {
    return {
      loading: false,
      subject: {},
      activeTabName: "Datos personales",
      isOpen: false,
      dateFormat: "dddd dd, MMMM yyyy",
      isDisabled: true,
      originalPerson: {}
    };
  },
  computed: {
    fullname() {
      return `${this.subject.person.lastname},
        ${this.subject.person.firstname}`;
    },
    gender() {
      let gender = "";
      if ((this.subject.person.gender || "") != "") {
        gender =
          this.subject.person.gender == "Masc" ? "Masculino" : "Femenino";
      }
      return gender;
    },
    subjectType() {
      return `${this.subject.minor ? "Menor" : "Adulto"}`;
    }
  },
  created() {
    this.loadSubject();
  },
  methods: {
    ...subjectActions(["fetchSubject", "saveSubjectData"]),
    filter: filter,
    loadSubject() {
      this.loading = true;
      this.fetchSubject({ subjectId: this.$route.params.id })
        .then(response => {
          this.subject = response.subject;
          this.activeTabName = Object.keys(this.subject.file_data)[0]
        })
        .catch(error => {
          console.log("error en fetchSubject", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (!date) return "Sin fecha de indagatoria"
      else
        return moment(date).format("DD/MM/YYYY");
    },
    toggleQuestionComponent(data) {
      let _this = this;
      map(data.ids, questionId => {
        let q = _this.$refs[`question_${questionId}`][0];
        if (data.show) {
          q.showQuestion();
        } else {
          q.hideQuestion();
        }
      });
    }
  }
};
</script>

<style lang="css">
.subject {
  .ipp-top-info {
    margin: 10px 0px;
    border-bottom: solid 3px #eee;
    padding-bottom: 15px;
  }
  .ipp-top-info .box-card {
    min-height: 300px;
    overflow-y: scroll;
  }
  .ipp-top-info .box-card h4 {
    margin-top: 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #eee;
  }
  .ipp-content ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
  .header-notification {
    float: right;
    margin: 0px;
    color: #f56c6c;
  }
}
</style>
