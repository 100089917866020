<template>
  <div
    v-if="program.id"
    class="page">
    <div class="page-header">
      <router-link
        :to="{ name: 'programs' }"
        style="float: left; margin: 5px 20px 5px 0px; font-size: 20px;">
        <i class="el-icon-back"></i>
      </router-link>
      <div class="controls">
        <router-link :to="{ name: 'editProgram', params: { id: $route.params.id } }">
          <el-button type="warning">Editar</el-button>
        </router-link>
      </div>
      <h2>
        Red
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        <router-link :to="{ name: 'programs' }">Programas</router-link>
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        {{ program.name }}
      </h2>
    </div>
    <div class="page-content">
      <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Nombre</div>
            <div class="ipp-data">{{ program.name }}</div>
          </div>
        </el-col>
      </el-row>
      <!--
      <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Tareas</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in program.tasks"
                  :key="item"
                  :value="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Requerimientos</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in program.requirements"
                  :key="item"
                  :value="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Habilidades</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in program.skills"
                  :key="item"
                  :value="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <br>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="ipp-label">Referentes</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in program.referents"
                  :key="item.id"
                  :value="item.id">
                  <router-link :to="{ name: 'referent', params: { id: item.id } }">
                    <i
                      style="font-size: 11px;"
                      class="el-icon-arrow-right"></i> {{ item.full_name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="ipp-label">Organizaciones</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in program.organizations"
                  :key="item.id"
                  :value="item.id">
                  <router-link :to="{ name: 'organization', params: { id: item.id } }">
                    <i
                      style="font-size: 11px;"
                      class="el-icon-arrow-right"></i> {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-card>
      -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: programActions } = namespace("program");

export default {
  name: "Program",
  data() {
    return {
      loading: false,
      program: {}
    };
  },
  created() {
    this.loadProgram();
  },
  methods: {
    ...programActions(["fetchProgram"]),
    loadProgram() {
      this.loading = true;
      this.fetchProgram({ programId: this.$route.params.id })
        .then(response => {
          this.program = response.program;
        })
        .catch(error => {
          console.log("error en fetchProgram", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="css">
#programs-data {
  background: #fff;
  height: 100%;
}

.ipp-row {
  padding: 15px 0px;
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
  .ipp-label {
    font-size: 0.8rem;
    color: teal;
    margin-bottom: 10px;
  }
  .ipp-data {
    font-size: 1.1rem;
    .no-data {
      color: #888;
    }
  }
}
</style>
