<template>
  <div
    v-if="intervention.id"
    class="page">
    <div class="page-header">
      <router-link
        :to="{ name: 'intervention', params: { id: $route.params.id } }"
        style="float: left; margin: 5px 20px 5px 0px; font-size: 20px;" >
        <i class="el-icon-back"></i>
      </router-link>
      <h2>
        Intervención
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        Editar
      </h2>
    </div>
    <div
      v-loading="loading"
      class="page-content"
      style="padding: 20px;">
      <intervention-form
        v-if="intervention.id"
        :intervention-data="intervention"
        @save="data => submit(data)"
        @cancel="cancel" />
    </div>
  </div>
</template>

<script>
import { forEach } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
import InterventionForm from "./InterventionForm";
const { mapActions: interventionActions } = namespace("intervention");

export default {
  name: "EditIntervention",
  components: { InterventionForm },
  data() {
    return {
      loading: false,
      intervention: {}
    };
  },
  created() {
    this.loadIntervention();
  },
  methods: {
    ...interventionActions([
      "fetchIntervention",
      "updateIntervention",
      "getInterventionData"
    ]),
    loadIntervention() {
      this.loading = true;
      this.fetchIntervention({ interventionId: this.$route.params.id })
        .then(response => {
          this.intervention = response.intervention;
          this.loadInterventionData();
        })
        .catch(error => {
          console.log("error en fetchIntervention", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit(data) {
      this.loading = true;
      let params = new FormData();
      forEach(data, (value, key) => {
        params.append(
          `intervention[${key}]`,
          data[key] == false ? data[key] : data[key] || ""
        );
      });
      this.updateIntervention({
        interventionId: this.intervention.id,
        payload: params
      })
        .then(() => {
          this.$message.success("Se actualizo la intervención");
          this.$router.push({
            name: "intervention",
            params: { id: this.intervention.id }
          });
        })
        .catch(error => {
          console.log("error grabando intervención", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$router.push({
        name: "intervention",
        params: { id: this.intervention.id }
      });
    },
    loadInterventionData() {
      this.getInterventionData({
        ippId: this.intervention.ipp_case_id,
        subjectId: this.intervention.subject_id
      }).then(response => {
        this.neededData = response.data;
      });
    }
  }
};
</script>

<style>
#interventions-data {
  background: #fff;
  height: 100%;
}

.data {
  padding: 0px 20px;
}
</style>
