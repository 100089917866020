<template>
  <div style="margin: 0px 0px">
    <div style="margin-bottom: 10px;">{{ child.label }}</div>
    <el-input
      v-if="child.data_type == 'string'"
      v-model="child.value"
      clearable
    />
    <el-select
      v-if="['combo', 'tag_list'].includes(child.data_type)"
      v-model="child.value"
      placeholder="Seleccione"
    >
      <el-option
        v-for="item in child.options"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
    <el-select
      v-if="child.data_type == 'sql_list'"
      v-model="child.value"
      placeholder="Seleccione"
    >
      <el-option
        v-for="item in childOptions"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapGetters: subjectGetters } = namespace("subject");
export default {
  name: "ChildQuestion",
  props: {
    child: {
      type: Object,
      required: true
    },
    answerId: {
      type: Number,
      required: true
    },
    subjectId: {
      type: Number,
      required: true
    },
    ippCaseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      values: []
    };
  },
  computed: {
    ...subjectGetters(["subjectSqlLists"]),
    childOptions() {
      if (this.child.options == "ipp_case.subjects") {
        return this.subjectSqlLists.other_subjects;
      } else if (this.child.options == "ipp_case.relations") {
        return this.subjectSqlLists.relations;
      } else if (this.child.options == "ipp_case.referents") {
        return this.subjectSqlLists.referents;
      } else if (this.child.options == "subject.objectives") {
        return this.subjectSqlLists.objectives;
      } else if (this.child.options == "subject.interests") {
        return this.subjectSqlLists.interests;
      } else {
        return [];
      }
    }
  }
};
</script>
