<template>
  <div class="page">
    <div class="page-header">
      <router-link
        :to="{ name: 'derivations', params: { id: subjectId } }"
        style="float: left; margin: 5px 20px 5px 0px; font-size: 20px;">
        <i class="el-icon-back"></i>
      </router-link>
      <h2>Nueva derivación</h2>
    </div>
    <div
      class="page-content"
      style="padding: 20px;">
      <derivation-form
        :derivation="derivation"
        @save="submit"
        @reset="reset" />
    </div>
  </div>
</template>

<script>
import { clone } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
import DerivationForm from "./DerivationForm";
const { mapActions: derivationActions } = namespace("derivation");

export default {
  name: "NewDerivation",
  components: { DerivationForm },
  data() {
    return {
      subjectId: null,
      derivation: {}
    };
  },
  created() {
    this.subjectId = clone(this.$route.params.id);
  },
  methods: {
    ...derivationActions(["addDerivation"]),
    submit() {
      this.loading = true;
      let derivationData = {
        subject_id: this.subjectId,
        date_from: this.derivation.date_from || null,
        reason: this.derivation.reason,
        type: this.derivation.type,
        state: this.derivation.state,
        derivable_type: this.derivation.derivable_type,
        derivable_id: this.derivation.derivable_id,
        next_control: this.derivation.next_control || null
      };
      this.addDerivation({ subjectId: this.subjectId, payload: derivationData })
        .then(() => {
          this.$message.success("Se creó la derivación");
          this.$router.push({
            name: "derivations",
            params: { id: this.subjectId }
          });
        })
        .catch(error => {
          console.log("error grabando derivación", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.derivation = {};
    }
  }
};
</script>

<style>
#programs-data {
  background: #fff;
  height: 100%;
}

.data {
  padding: 0px 20px;
}
</style>
