<template>
  <div
    v-if="referent.id"
    class="page">
    <div class="page-header">
      <div class="controls">
        <router-link :to="{ name: 'editReferent', params: { id: $route.params.id } }" >
          <el-button type="warning">Editar</el-button>
        </router-link>
      </div>
      <h2>
        Red
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        <router-link :to="{ name: 'referents' }">Referente</router-link>
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        {{ referent.lastname }}, {{ referent.firstname }}
      </h2>
    </div>
    <div class="page-content">
      <el-row>
        <el-col :span="12">
          <div class="ipp-row">
            <div class="ipp-label">Tipo</div>
            <div class="ipp-data">{{ referent.ref_type_desc || "SD" }}</div>
          </div>
        </el-col>
        <!-- <el-col :span="24">
          <div class="ipp-row">
            <div class="ipp-label">Cargo</div>
            <div class="ipp-data">{{ referent.position || "SD" }}</div>
          </div>
        </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Teléfono</div>
            <div class="ipp-data">{{ referent.phone || "SD" }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Celular</div>
            <div class="ipp-data">{{ referent.cellphone || "SD" }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Email</div>
            <div class="ipp-data">{{ referent.email || "SD" }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="ipp-row">
            <div class="ipp-label">Dirección</div>
            <div class="ipp-data">
              {{ referent.address }} - {{ referent.city.name }} -
              {{ referent.department.name }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: referentActions } = namespace("referent");

export default {
  name: "Referent",
  data() {
    return {
      loading: false,
      referent: {}
    };
  },
  created() {
    this.loadReferent();
  },
  methods: {
    ...referentActions(["fetchReferent"]),
    loadReferent() {
      this.loading = true;
      this.fetchReferent({ referentId: this.$route.params.id })
        .then(response => {
          this.referent = response.referent;
        })
        .catch(error => {
          console.log("error en fetchReferent", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="css">
#referents-data {
  background: #fff;
  height: 100%;
}

.ipp-row {
  padding: 15px 0px;
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
  .ipp-label {
    font-size: 0.8rem;
    color: teal;
    margin-bottom: 10px;
  }
  .ipp-data {
    font-size: 1.1rem;
    .no-data {
      color: #888;
    }
  }
}
</style>
