import { render, staticRenderFns } from "./Victimizers.vue?vue&type=template&id=29382827&"
import script from "./Victimizers.vue?vue&type=script&lang=js&"
export * from "./Victimizers.vue?vue&type=script&lang=js&"
import style0 from "./Victimizers.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports