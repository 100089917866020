<template>
  <router-link
    :to="routeName"
    :class="className">
    <span>{{ displayName }}</span>
  </router-link>
</template>

<script>
export default {
  name: "SideMenuItem",
  props: {
    isMain: {
      type: Boolean,
      required: true
    },
    tooltipContent: {
      type: String,
      required: true
    },
    routeName: {
      type: Object,
      required: true
    },
    displayName: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: true
    }
  }
};
</script>
