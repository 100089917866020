import Interventions from "@/components/Intervention/Interventions";
import Intervention from "@/components/Intervention/Intervention";
import EditIntervention from "@/components/Intervention/EditIntervention";
import NewIntervention from "@/components/Intervention/NewIntervention";

const intervention_routes = [
  {
    path: "intervenciones",
    name: "interventions",
    component: Interventions,
    meta: { requireAuth: true, requireAdmin: false }
  },
  {
    path: "intervenciones/nuevo",
    name: "newIntervention",
    component: NewIntervention,
    meta: { requireAuth: true, requireAdmin: true }
  },
  {
    path: "intervenciones/:id",
    name: "intervention",
    component: Intervention,
    meta: { requireAuth: true, requireAdmin: false }
  },
  {
    path: "intervenciones/:id/editar",
    name: "editIntervention",
    component: EditIntervention,
    meta: { requireAuth: true, requireAdmin: true }
  }
];

export default intervention_routes;
