<template>
  <el-form
    ref="form"
    :model="organization"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Nombre de la Organización">
          <el-input v-model="organization.name" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Entidad Superior">
          <el-input v-model="organization.leading_entity" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Telefono">
          <el-input v-model="organization.phone" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Email">
          <el-input v-model="organization.email" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Página Web">
          <el-input v-model="organization.website" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item>
          <span slot="label">Dirección</span>
          <div class="el-input">
            <vue-google-autocomplete
              id="map"
              ref="address"
              :country="['ar', 'br']"
              class="el-input__inner"
              placeholder="Direccion"
              autocomplete="false"
              style="width: 100%;"
              @placechanged="getAddressData"
            />
          </div>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="8">
        <el-form-item label="Ciudad">
          <el-input v-model="organization.city_id"/>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Partido/Departamento">
          <el-input v-model="organization.department_id"/>
        </el-form-item>
      </el-col> -->
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Contacto">
          <el-input v-model="organization.contact_name" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Forma de contacto">
          <el-select
            v-model="organization.contact_mode"
            filterable
            style="width: 100%"
            placeholder="Seleccione"
          >
            <el-option
              v-for="item in contactModeOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="8">
        <el-form-item label="Nombre del recurso">
          <el-input v-model="organization.resource_name"/>
        </el-form-item>
      </el-col> -->
    </el-row>
    <!--
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Duración">
          <el-input v-model="organization.program_duration"/>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Unidades">
          <el-select
            v-model="organization.position"
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in durationTypeOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Periodicidad de inicio">
          <el-input v-model="organization.program_start_periodicity"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Tipos de Recurso">
          <el-select
            v-model="organization.resource_types"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in resourceTypeOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Ámbitos de trabajo">
          <el-select
            v-model="organization.scopes"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in scopeOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Descripciones">
          <el-select
            v-model="organization.descriptions"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in descriptionOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Características">
          <el-select
            v-model="organization.characteristics"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in characteristicOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Objetivos">
          <el-select
            v-model="organization.aims"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in aimOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Palabras clave">
          <el-select
            v-model="organization.keywords"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in keywordOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Condiciones de ingreso">
          <el-select
            v-model="organization.entry_conditions"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in entryConditionOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Formas de ingreso">
          <el-select
            v-model="organization.entry_modes"
            multiple
            filterable
            style="width: 100%"
            placeholder="Seleccione">
            <el-option
              v-for="item in entryModeOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    -->
    <hr >
    <el-form-item>
      <el-button
        type="success" 
        @click="onSubmit">Guardar</el-button>
      <el-button
        type="danger" 
        @click="onReset">Recargar</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import VueGoogleAutocomplete from "../Shared/VueGoogleAutocomplete";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: listValuesActions } = namespace("admin");

export default {
  name: "OrganizationForm",
  components: { VueGoogleAutocomplete },
  props: {
    organization: { type: Object, required: true }
  },
  data() {
    return {
      durationTypeOptions: ["Dias", "Meses"],
      contactModeOptions: ["Mail", "Persona", "Telefono", "Web"],
      resourceTypeOptions: [],
      scopeOptions: [],
      descriptionOptions: [],
      characteristicOptions: [],
      aimOptions: [],
      keywordOptions: [],
      entryConditionOptions: [],
      entryModeOptions: [],
      loading: false
    };
  },
  created() {
    this.loadOrganizationListOfValues();
    this.$nextTick(() => {
      this.$refs.address.update(this.organization.address);
    });
  },
  methods: {
    ...listValuesActions(["fetchListValues"]),
    loadOrganizationListOfValues() {
      this.loading = true;
      this.fetchListValues({ list: "organization_resource_types" })
        .then(response => {
          this.resourceTypeOptions = response;
        })
        .catch(error => {
          console.log(
            "error en fetchListValues organization_resource_types",
            error
          );
        });
      this.fetchListValues({ list: "organization_scopes" })
        .then(response => {
          this.scopeOptions = response;
        })
        .catch(error => {
          console.log("error en fetchListValues organization_scopes", error);
        });
      this.fetchListValues({ list: "organization_descriptions" })
        .then(response => {
          this.descriptionOptions = response;
        })
        .catch(error => {
          console.log(
            "error en fetchListValues organization_descriptions",
            error
          );
        });
      this.fetchListValues({ list: "organization_characteristics" })
        .then(response => {
          this.characteristicOptions = response;
        })
        .catch(error => {
          console.log(
            "error en fetchListValues organization_characteristics",
            error
          );
        });
      this.fetchListValues({ list: "organization_aims" })
        .then(response => {
          this.aimOptions = response;
        })
        .catch(error => {
          console.log("error en fetchListValues organization_aims", error);
        });
      this.fetchListValues({ list: "organization_keywords" })
        .then(response => {
          this.keywordOptions = response;
        })
        .catch(error => {
          console.log("error en fetchListValues organization_keywords", error);
        });
      this.fetchListValues({ list: "organization_entry_conditions" })
        .then(response => {
          this.entryConditionOptions = response;
        })
        .catch(error => {
          console.log(
            "error en fetchListValues organization_entry_conditions",
            error
          );
        });
      this.fetchListValues({ list: "organization_entry_modes" })
        .then(response => {
          this.entryModeOptions = response;
        })
        .catch(error => {
          console.log(
            "error en fetchListValues organization_entry_modes",
            error
          );
        });
      this.loading = false;
    },
    onSubmit() {
      this.$emit("save");
    },
    onReset() {
      this.$emit("reset");
    },
    getAddressData(addressData, placeResultData) {
      console.log(addressData);
      this.organization.address = placeResultData.formatted_address;
      this.organization.coordinates = `POINT(${addressData.longitude} ${addressData.latitude})`;

      this.$nextTick(() => {
        this.$refs.address.update(this.organization.address);
      });
    }
  }
};
</script>
