<template>
  <div id="app">
    <div style="height: 100%;">
      <router-view />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: authActions } = namespace("auth");

export default {
  name: "JjrFrontend",
  data() {
    return {
      loading: true,
      checked: false,
      message: null
    };
  },
  created() {
    this.checkLogged();
  },
  methods: {
    ...authActions(["checkUser"]),
    checkLogged() {
      this.checkUser()
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch(response => {
          console.log('Cheeck logged in', response)
          this.$router.push({ name: "login" });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="css">

html {
  height: 100%;
}
body {
  background-color: #fff !important;
  height: 100%;
  margin: 0px;
  font-family: "Ubuntu", sans-serif;
  overflow: hidden;
}

#app {
  min-height: 100%;
}
a {
  text-decoration: none;
  color: #888;
}
a:active,
a:focus {
  outline: none !important;
  border: none;
}
hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 40px 0px;
}

.loading-message {
  margin: 10px 0px;
  color: $color-secondary;
}
.loading-footer {
  position: absolute;
  bottom: 2em;
  color: #999;
  left: 0px;
  width: 100%;
  font-size: 13px;
  text-align: center;
}
.loading-error {
  color: $color-hazard;
  display: flex;
  align-items: center;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  .el-button {
    margin: 20px 0px;
  }
  i {
    font-size: 40px;
    margin: 20px 0px;
  }
}
</style>
