<template>
  <div v-if="ipp.id">
    <el-row>
      <el-col :span="24">
        <div class="ipp-row">
          <div class="ipp-label">Lugar de los hechos</div>
          <div class="ipp-data">
            <div v-if="!editAddress">
              <span :class="!event_address ? 'no-data' : ''">{{
                event_address || "S/D"
              }}</span>
              <span
                v-is-disabled-for="['efector']"
                style="float: right">
                <a @click="toggleEdit('editAddress')">
                  <i class="el-icon-edit-outline"></i>
                </a>
              </span>
            </div>
            <div v-else>
              <el-row :gutter="20">
                <el-col :span="16">
                  <div class="el-input el-input__small">
                    <vue-google-autocomplete
                      id="map"
                      ref="address"
                      :country="['ar', 'br']"
                      class="el-input__inner"
                      placeholder="Direccion del hecho"
                      autocomplete="false"
                      style="width: 100%;"
                      @placechanged="getAddressData" />
                  </div>
                </el-col>
                <el-col :span="8">
                  <el-button-group style="float: right">
                    <el-button
                      type="primary"
                      size="small"
                      @click="update('editAddress')" >
                      Guardar
                    </el-button>
                    <el-button
                      size="small"
                      @click="rollbackEdit('editAddress', 'event_address')">
                      Cancelar
                    </el-button>
                  </el-button-group>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="ipp-row">
          <div class="ipp-label">Fecha / Hora de los hechos</div>
          <div class="ipp-data">
            <div v-if="!editDateTime">
              <span v-if="event_date">
                {{ event_date | moment("dddd DD [de] MMMM, YYYY") }}
              </span>
              <span
                v-else
                class="no-data">
                Sin fecha de hechos
              </span>
              <span v-if="event_hour"> - {{ `${event_hour} HS` }}</span>
              <span
                v-is-disabled-for="['efector']"
                style="float: right" >
                <a @click="toggleEdit('editDateTime')">
                  <i class="el-icon-edit-outline"></i>
                </a>
              </span>
            </div>
            <div v-else>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-date-picker
                    v-model="event_date"
                    :clearable="true"
                    :format="dateFormat"
                    style="width: 100%;"
                    name="event_date"
                    size="small"
                    placeholder="Fecha del hecho" />
                </el-col>
                <el-col :span="8">
                  <el-time-select
                    v-model="event_hour"
                    :clearable="true"
                    :disabled="ipp.eventHourDisabled"
                    :picker-options="pickerOptions"
                    style="width: 100%;"
                    name="event_hour"
                    size="small"
                    placeholder="Hora del hecho" />
                </el-col>
                <el-col
                  v-is-enabled-for="['admin', 'coordinador', 'operador']"
                  :span="8" >
                  <el-button-group style="float: right;">
                    <el-button
                      type="primary"
                      size="small"
                      @click="update('editDateTime')" >
                      Guardar
                    </el-button>
                    <el-button
                      size="small"
                      @click="rollbackEdit('editDateTime', 'event_date')" >
                      Cancelar
                    </el-button>
                  </el-button-group>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="ipp-row">
          <div class="ipp-label">Denuncia realizada el</div>
          <div class="ipp-data">
            <div v-if="!editReport">
              <span v-if="report_date">{{
                report_date | moment("dddd DD [de] MMMM, YYYY")
              }}</span>
              <span
                v-else
                class="no-data">
                Sin fecha de denuncia
              </span>
              <span
                v-is-disabled-for="['efector']"
                style="float: right">
                <a @click="toggleEdit('editReport')">
                  <i class="el-icon-edit-outline"></i>
                </a>
              </span>
            </div>
            <div v-else>
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-date-picker
                    v-model="report_date"
                    :clearable="true"
                    :format="dateFormat"
                    :picker-options="pickerReportOptions"
                    style="width: 100%;"
                    name="report_date"
                    size="small"
                    placeholder="Fecha de la denuncia" />
                </el-col>
                <el-col :span="8">
                  <el-button-group style="float: right;">
                    <el-button
                      type="primary"
                      size="small"
                      @click="update('editReport')" >
                      Guardar
                    </el-button>
                    <el-button
                      size="small"
                      @click="rollbackEdit('editReport', 'report_date')">
                      Cancelar
                    </el-button>
                  </el-button-group>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="ipp-row">
          <div class="ipp-label">Crímen(es)</div>
          <div class="ipp-data">
            <ul>
              <li
                v-for="crime in ipp.crimes"
                :key="crime.id"
                :value="crime.id">
                {{ crime.crime }} - {{ crime.status }}
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="ipp-row">
          <div class="ipp-label">Comisaria</div>
          <div class="ipp-data">{{ ipp.police_station.name }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="ipp-row">
          <div class="ipp-label">Origen (Fiscalia/Juzgado/Otro)</div>
          <div class="ipp-data">{{ ipp.origin.name }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { clone } from "lodash";
import VueGoogleAutocomplete from "../../Shared/VueGoogleAutocomplete";

import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: ippActions } = namespace("ipp");

export default {
  name: "IppCaseInfo",
  components: { VueGoogleAutocomplete },
  props: {
    ipp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      event_date: "",
      event_hour: "",
      event_address: "",
      report_date: "",
      coordinates: "",
      editAddress: false,
      editDateTime: false,
      editReport: false,
      dateFormat: "dddd dd, MMMM yyyy",
      pickerOptions: {
        start: "00:00",
        step: "00:15",
        end: "23:59"
      },
      pickerReportOptions: {
        disabledDate: time => {
          let limitDate = this.event_date || new Date();
          return time.getTime() < limitDate.getTime();
        }
      }
    };
  },
  created() {
    this.setData();
  },
  methods: {
    ...ippActions(["updateIpp"]),
    setData() {
      //console.log("SET DATA", this.ipp);
      this.event_address = clone(this.ipp.event_address);
      this.event_date = clone(this.ipp.event_date);
      this.report_date = clone(this.ipp.report_date);
      this.event_hour = clone(this.ipp.event_hour);
      this.coordinates = clone(this.ipp.coordinates);
    },
    toggleEdit(key) {
      this[key] = !this[key];
    },
    rollbackEdit(key, field) {
      if (field === "event_date") {
        this.event_time = clone(this.ipp.event_time);
        this.event_date = clone(this.ipp.event_date);
      } else {
        this[field] = clone(this.ipp[field]);
      }
      this[key] = false;
    },
    update(key) {
      this.updateIpp({
        ippId: this.ipp.id,
        data: {
          event_address: this.event_address,
          event_date: this.event_date,
          report_date: this.report_date,
          event_hour: this.event_hour,
          coordinates: this.coordinates
        }
      })
        .then(response => {
          this[key] = !this[key];
          this.event_address = response.ipp_case.event_address;
          this.event_date = response.ipp_case.event_date;
          this.report_date = response.ipp_case.report_date;
          this.event_hour = response.ipp_case.event_hour;
          this.coordinates = response.ipp_case.coordinates;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getAddressData: function(addressData, placeResultData) {
      console.log(addressData);
      console.log(placeResultData);
      this.event_address = placeResultData.formatted_address;
      this.coordinates = `POINT(${addressData.longitude} ${addressData.latitude})`;

      this.$nextTick(() => {
        this.$refs.address.update(this.event_address);
      });
    }
  }
};
</script>

<style lang="css">
.ipp-row {
  padding: 15px 0px;
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
  .ipp-label {
    font-size: 0.8rem;
    color: teal;
    margin-bottom: 10px;
  }
  .ipp-data {
    font-size: 1.1rem;
    .no-data {
      color: #888;
    }
  }
}
</style>
