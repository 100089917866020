<template>
  <div
    v-if="organization.id"
    class="page">
    <div class="page-header">
      <div class="controls">
        <router-link :to="{ name: 'editOrganization', params: { id: $route.params.id } }">
          <el-button type="warning">Editar</el-button>
        </router-link>
      </div>
      <h2>
        Red
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        <router-link :to="{ name: 'organizations' }">Organizaciones</router-link>
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        {{ organization.name }}
      </h2>
    </div>
    <div class="page-content">
      <el-row>
        <!-- <el-col :span="12">
          <div class="ipp-row">
            <div class="ipp-label">Entidad superior</div>
            <div class="ipp-data">{{ organization.leading_entity }}</div>
          </div>
        </el-col> -->
        <el-col :span="24">
          <div class="ipp-row">
            <div class="ipp-label">Dirección</div>
            <div class="ipp-data">
              {{ organization.address }} - {{ organization.city.name }} -
              {{ organization.department.name }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="ipp-row">
            <div class="ipp-label">Nombre del contacto</div>
            <div class="ipp-data">{{ organization.contact_name }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="ipp-row">
            <div class="ipp-label">Forma de contacto preferida</div>
            <div class="ipp-data">{{ organization.contact_mode || "S/D" }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Teléfono</div>
            <div class="ipp-data">{{ organization.phone }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Email</div>
            <div class="ipp-data">{{ organization.email }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Página web</div>
            <div class="ipp-data">{{ organization.website }}</div>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Nombre del recurso</div>
            <div class="ipp-data">{{ organization.resource_name || "S/D" }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Duración del programa</div>
            <div class="ipp-data">{{ organization.program_duration_description || "S/D" }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Periodicidad del programa</div>
            <div class="ipp-data">{{ organization.program_start_periodicity || "S/D" }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Acepta externos?</div>
            <div class="ipp-data">{{ organization.accepts_externals ? "Si" : "No" }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Acepta menores?</div>
            <div class="ipp-data">{{ organization.accepts_minors ? "Si" : "No" }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="ipp-row">
            <div class="ipp-label">Vacantes</div>
            <div class="ipp-data">{{ organization.vacants || "S/D" }}</div>
          </div>
        </el-col>
      </el-row> -->
      <br >
      <!-- <el-card>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="ipp-label">Tipos de recurso</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.resource_types"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="ipp-label">Ambitos</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.scopes"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <br>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="ipp-label">Descripciones</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.descriptions"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="ipp-label">Caracteristicas</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.characteristics"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <br>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="ipp-label">Objetivos</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.aims"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="ipp-label">Palabras clave</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.keywords"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <br>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="ipp-label">Condiciones de ingreso</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.entry_conditions"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="ipp-label">Forma de ingreso</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.entry_modes"
                  :key="item"
                  :value="item">
                  <i
                    style="font-size: 11px;"
                    class="el-icon-arrow-right"></i> {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <br>
      <el-card>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="ipp-label">Referentes</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.referents"
                  :key="item.id"
                  :value="item.id">
                  <router-link :to="{ name: 'referent', params: { id: item.id } }">
                    <i
                      style="font-size: 11px;"
                      class="el-icon-arrow-right"></i> {{ item.full_name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="ipp-label">Programas</div>
            <div class="ipp-data">
              <ul>
                <li
                  v-for="item in organization.programs"
                  :key="item.id"
                  :value="item.id">
                  <router-link :to="{ name: 'program', params: { id: item.id } }">
                    <i
                      style="font-size: 11px;"
                      class="el-icon-arrow-right"></i> {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-card> -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const {
  mapActions: organizationActions
} = namespace("organization");

export default {
  name: "Organization",
  data() {
    return {
      loading: false,
      organization: {}
    };
  },
  created() {
    this.loadOrganization();
  },
  methods: {
    ...organizationActions(["fetchOrganization"]),
    loadOrganization() {
      this.loading = true;
      this.fetchOrganization({ organizationId: this.$route.params.id })
        .then(response => {
          this.organization = response.organization;
        })
        .catch(error => {
          console.log("error en fetchOrganization", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="css">
#organizations-data {
  background: #fff;
  height: 100%;
}

.ipp-row {
  padding: 15px 0px;
  border-bottom: solid 1px #eee;
  margin-bottom: 10px;
}
.ipp-label {
  font-size: 0.8rem;
  color: teal;
  margin-bottom: 10px;
}
.ipp-data {
  font-size: 1.1rem;
  .no-data {
    color: #888;
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      padding: 5px 0px;
    }
  }
}
</style>
