<template>
  <div
    v-loading="loading"
    class="page subject" >
    <div v-if="subject.id">
      <div class="page-header">
        <router-link
          :to="{ name: 'ipp', params: { id: subject.ipp_case_id } }"
          style="float: left; margin: 5px 20px 5px 0px; font-size: 20px;" >
          <i class="el-icon-back"></i>
        </router-link>
        <h2>
          <span>Derivaciones</span>
          - {{ subject.full_name }} ({{ subject.role }})
          <router-link
            :to="{
              name: 'newDerivation',
              params: {
                id: subject.id
              }
            }"
          >
            <el-button
              size="mini"
              type="primary">
              Nueva derivación
            </el-button>
          </router-link>
        </h2>
      </div>
      <div class="page-content no-bottom">
        <div class="list">
          <div
            v-if="derivations.length > 0"
            class="ipp-content">
            <ul>
              <li
                v-for="(derivation, index) in derivations"
                :key="index">
                <derivation
                  :derivation="derivation"
                  @remove="alertRemoveDerivation(derivation)" />
              </li>
            </ul>
          </div>
          <div v-else>
            <p>No hay derivaciones</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filter, clone } from "lodash";
import Derivation from "./Derivation";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: derivationActions } = namespace("derivation");

export default {
  name: "Derivations",
  components: {
    Derivation
  },
  data() {
    return {
      loading: false,
      dataLoaded: false,
      subjectId: null,
      subject: {},
      derivations: []
    };
  },
  created() {
    this.subjectId = clone(this.$route.params.id);
    this.loadSubjectDerivations();
  },
  methods: {
    ...derivationActions(["fetchDerivations", "deleteDerivation"]),
    filter: filter,
    loadSubjectDerivations() {
      this.loading = true;
      this.fetchDerivations({ subjectId: this.subjectId })
        .then(response => {
          this.subject = response.subject;
          this.derivations = response.derivations;
          this.dataLoaded = true;
        })
        .catch(error => {
          console.log("error en fetchDerivations", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    alertRemoveDerivation(derivation) {
      this.$confirm(
        "Esto eliminará la derivación. ¿Desea continuar?",
        "Atención",
        {
          confirmButtonText: "Si",
          cancelButtonText: "No, cancelar",
          type: "warning"
        }
      ).then(() => {
        this.remove(derivation);
        this.$message({
          type: "success",
          message: "Derivación eliminada"
        });
      });
    },
    remove(derivation) {
      this.loading = true;
      let currentIndex = this.derivations.indexOf(derivation);
      this.deleteDerivation({
        subjectId: this.subjectId,
        derivationId: derivation.id
      })
        .then(() => {
          this.derivations.splice(currentIndex, 1);
        })
        .catch(error => {
          console.log("error en deleteDerivation", error);
        });
      this.loading = false;
    }
  }
};
</script>

<style lang="css">
.subject .ipp-top-info {
  margin: 10px 0px;
  border-bottom: solid 3px #eee;
  padding-bottom: 15px;
}
.subject .ipp-top-info .box-card {
  min-height: 300px;
  overflow-y: scroll;
}
.subject .ipp-top-info .box-card h4 {
  margin-top: 0px;
  padding-bottom: 10px;
  border-bottom: solid 1px #eee;
}
.subject .ipp-content ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.subject .header-notification {
  float: right;
  margin: 0px;
  color: #f56c6c;
}
</style>
