import * as types from "../types";
import axios from "axios";
import JWTDecode from "jwt-decode";

const checkUser = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    if (token) {
      const data = JWTDecode(token);
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      if (data.email === user.email) {
        commit(types.LOGIN_SUCCESS, { token: token, user: user });
        resolve(user);
      } else {
        commit(types.LOGIN_ERROR);
        reject("No user logged in");
      }
    } else {
      commit(types.LOGIN_ERROR);
      reject("No user logged in");
    }
  });
};

const login = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/login", { auth: payload })
      .then(response => {
        const token = response.data.auth_token;
        let user = response.data.user;
        commit(types.LOGIN_SUCCESS, { token: token, user: user });
        resolve(user);
      })
      .catch(error => {
        commit(types.LOGIN_ERROR);
        reject(error);
      });
  });
};

const logout = ({ commit }) => {
  return new Promise((resolve) => {
    commit(types.LOGOUT_SUCCESS);
    resolve();
  });
};

const setJurisdiction = ({ commit }, { jurisdictionUserId, isDefault }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`users/set_default_jurisdiction`, {
        jur_id: jurisdictionUserId,
        is_default: isDefault
      })
      .then(response => {
        let user = JSON.parse(localStorage.getItem("user"));
        user.jurisdiction = response.data.jurisdiction;
        localStorage.setItem("user", JSON.stringify(user));
        commit(types.UPDATE_CURRENT_USER, user);
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const generatePasswordResetRequest = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/users/create_password_reset_request", { email: payload })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const resetPassword = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/users/reset_password", { password_reset_request: payload })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
};

export default {
  checkUser,
  login,
  logout,
  generatePasswordResetRequest,
  resetPassword,
  setJurisdiction
};
