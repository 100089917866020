<template>
  <el-form>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form-item>
          <div :class="['el-input', newVictimizer.noDNI ? 'is-disabled' : '']">
            <the-mask
              v-model="newVictimizer.document_number"
              :mask="['##.###.###', '#.###.###']"
              :disabled="newVictimizer.noDNI"
              suffix-icon="icono-arg-dni"
              name="document_number"
              class="el-input__inner"
              style="width: 100%;"
              placeholder="Buscar por nro de DNI" />
          </div>
          <div>
            <el-checkbox
              v-model="newVictimizer.noDNI"
              label="Sin DNI"
              @change="cleanField('document_number')" />
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-button
          :disabled="newVictimizer.noDNI"
          style="width: 100%"
          @click="validateDNI()" >
          <i class="el-icon-check" ></i>
        </el-button>
      </el-col>
      <el-col :span="6">
        <el-form-item style="width: 100%;">
          <el-radio-group
            v-model="newVictimizer.gender"
            :disabled="!dniPresent"
            style="width: 100%;" >
            <el-radio-button label="Fem" />
            <el-radio-button label="Masc" />
            <el-radio-button label="Otro" />
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item style="width: 100%;">
          <el-date-picker
            v-model="newVictimizer.birth_date"
            :clearable="true"
            :disabled="!dniPresent"
            :format="dateFormat"
            style="width: 100%;"
            name="birth_date"
            placeholder="Fecha de nacimiento"
            @change="checkMinor()" />
          <div>
            <el-switch
              v-model="newVictimizer.minor"
              active-text="Menor"
              inactive-text="Adulto"
              @change="checkMinor()" />
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item>
          <el-input
            v-model="newVictimizer.firstname"
            :disabled="!dniPresent"
            name="name"
            style="width: 100%;"
            placeholder="Nombre" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <el-input
            v-model="newVictimizer.lastname"
            :disabled="!dniPresent"
            name="last_name"
            style="width: 100%;"
            placeholder="Apellido" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="20">
        <el-form-item>
          <div :class="['el-input', isDisabled ? 'is-disabled' : '']">
            <vue-google-autocomplete
              id="map"
              ref="address"
              :country="['ar', 'br']"
              :readonly="isDisabled"
              :disabled="isDisabled"
              name="address"
              classname="el-input__inner"
              placeholder="Direccion completa"
              autocomplete="false"
              style="width: 100%;"
              @placechanged="getAddressData" />
            <div>
              <el-checkbox
                v-model="newVictimizer.noAddress"
                label="Direccion desconocida"
                @change="cleanField('address')" />
            </div>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-button
          :disabled="newVictimizer.noAddress || !newVictimizer.address"
          style="width: 100%;"
          @click="validateAddress()" >
          <i class="el-icon-check"></i>
        </el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          :disabled="!dataValidated"
          style="width: 100%;"
          @click="checkNewPerson()" >
          <i class="el-icon-plus"></i>
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Fecha de indagatoria">
          <el-date-picker
            v-model="newVictimizer.inquiry_date"
            :clearable="true"
            :format="dateFormat"
            name="inquiry_date"
            placeholder="Fecha de indagatoria" />
        </el-form-item>
      </el-col>
      <el-col
        v-if="!requestLocal"
        :span="12">
        <el-form-item label="Tipos de victimario">
          <el-checkbox-button
            v-model="newVictimizer.legal"
            type="primary">
            Legal
          </el-checkbox-button>
          <el-checkbox-button
            v-model="newVictimizer.restorative"
            type="primary">
            Restaurativo
          </el-checkbox-button>
          <el-checkbox-button
            v-model="newVictimizer.conflictual"
            type="primary">
            Conflictual
          </el-checkbox-button>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      v-if="!requestLocal"
      :gutter="20">
      <el-col
        :span="24"
        style="text-align: right;">
        <el-button
          :disabled="!enableSave"
          type="primary"
          @click="updateIpp()">
          Guardar
        </el-button>
        <el-button @click="closeModal()">
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { extend, clone, forEach } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
import VueGoogleAutocomplete from "../../Shared/VueGoogleAutocomplete";
const { mapActions: ippActions } = namespace("ipp");
export default {
  name: "VictimizerInputs",
  components: { VueGoogleAutocomplete },
  props: {
    ippFormVictimizers: {
      required: true,
      type: Array
    },
    requestLocal: {
      required: false,
      type: Boolean,
      default: () => {
        return false;
      }
    },
    ippId: {
      required: false,
      type: Number,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      loading: false,
      dniError: "",
      dateFormat: "dddd dd, MMMM yyyy",
      enableSave: false,
      newVictimizer: {
        firstname: "",
        lastname: "",
        document_number: "",
        birth_date: "",
        gender: "",
        address: "",
        role: "victimizer",
        noAddress: false,
        noDNI: false,
        dniValidated: false,
        addressValidated: false,
        minor: null,
        legal: false,
        restorative: false,
        conflictual: false
      }
    };
  },
  computed: {
    dataValidated() {
      let hasName =
        this.newVictimizer.firstname.trim() !== "" &&
        this.newVictimizer.lastname.trim() !== "";
      let dniValid =
        this.newVictimizer.noDNI || this.newVictimizer.dniValidated;
      let addressValid =
        this.newVictimizer.noAddress || this.newVictimizer.addressValidated;

      return hasName && dniValid && addressValid;
    },
    dniPresent() {
      return (
        (this.newVictimizer.document_number &&
          this.newVictimizer.dniValidated) ||
        this.newVictimizer.noDNI
      );
    },
    isDisabled() {
      return this.newVictimizer.noAddress || !this.dniPresent;
    }
  },
  methods: {
    ...ippActions([
      "checkDNI",
      "addPerson",
      "createPerson",
      "updateSubjectIpp"
    ]),
    cleanField(field) {
      this.newVictimizer[field] = "";
    },
    validateDNI() {
      if (this.isValidFormat()) {
        if (this.isNotSelected()) {
          this.checkDNI({ dni: this.newVictimizer.document_number })
            .then(response => {
              extend(this.newVictimizer, response.person);
              if (this.requestLocal) {
                this.addVictimizer();
              } else {
                this.enableSave = true;
              }
            })
            .catch(error => {
              console.log("error en checkDNI", error);
              this.newVictimizer.dniValidated = true;
            });
        } else {
          this.$message({
            type: "error",
            message: "Victimario ya seleccionado"
          });
        }
      } else {
        this.$message({
          type: "error",
          message: this.dniError
        });
      }
    },
    validateAddress() {
      this.newVictimizer.addressValidated =
        this.newVictimizer.address !== "" &&
        this.newVictimizer.coordinates !== "";
    },
    checkMinor() {
      if (
        (this.newVictimizer.birth_date != null) &
        (this.newVictimizer.birth_date != "")
      ) {
        this.newVictimizer.minor =
          this.getAge(this.newVictimizer.birth_date) < 18;
      }
    },
    addVictimizer() {
      this.savePersonToIpp();
    },
    savePersonToIpp() {
      this.addPerson({
        person: this.newVictimizer,
        role: "victimizers",
        minor: this.newVictimizer.minor
      })
        .then(() => {
          let vict = clone(this.newVictimizer);
          this.$message({
            type: "success",
            message: `Se agrego a ${vict.firstname} ${vict.lastname}`
          });
          this.resetForm();
        })
        .catch(error => {
          console.log("error en addPerson", error);
        });
    },
    checkNewPerson() {
      if (this.requestLocal) {
        this.savePersonToIpp();
      } else {
        this.createPerson({
          person: this.newVictimizer,
          role: "victimizers",
          minor: this.newVictimizer.minor
        })
          .then(response => {
            this.enableSave = true;
            this.newVictimizer = clone(response);
            this.$message({
              type: "success",
              message: `Se agrego al sistema
                ${this.newVictimizer.firstname}
                ${this.newVictimizer.lastname}
              `
            });
          })
          .catch(error => {
            console.log("error en addPerson", error);
          });
      }
    },
    updateIpp() {
      this.updateSubjectIpp({
        ippId: this.ippId,
        subject: {
          person_id: this.newVictimizer.id,
          role: "victimizer",
          minor: this.newVictimizer.minor,
          legal: this.newVictimizer.legal,
          restorative: this.newVictimizer.restorative,
          conflictual: this.newVictimizer.conflictual
        }
      })
        .then(response => {
          this.resetForm();
          this.$emit("add-new-victimizer", response.data);
        })
        .catch(error => {
          forEach(error.response.data, (message, index) => {
            let time = Number(index) * 600;
            setTimeout(() => {
              this.$notify.error({
                title: "Error",
                message: message,
                duration: 5000
              });
            }, time);
          });
        });
    },
    isValidFormat() {
      if (!this.newVictimizer.document_number) {
        this.dniError = "No hay DNI";
        return false;
      }
      let leng = this.newVictimizer.document_number.length;
      if (leng < 7) {
        this.dniError = "El formato es invalido";
        return false;
      }
      return true;
    },
    resetForm() {
      this.newVictimizer = {
        id: "",
        firstname: "",
        lastname: "",
        document_number: "",
        gender: "",
        address: "",
        role: "victimizer",
        noAddress: false,
        noDNI: false,
        dniValidated: false,
        addressValidated: false,
        minor: null
      };
    },
    isNotSelected() {
      let p = this.ippFormVictimizers.find(
        v => v.person.document_number == this.newVictimizer.document_number
      );
      if (p) {
        console.log("No p");
        this.dniError = "El dni ingresado ya esta seleccionado";
        return false;
      }
      return true;
    },
    getAge(dateString) {
      let today = new Date();
      let birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    closeModal() {
      this.resetForm();
      //console.log("close modal");
      this.$emit("close-victimizer-modal");
    },
    getAddressData: function(addressData, placeResultData) {
      // console.log(addressData);
      // console.log(placeResultData);
      this.newVictimizer.address = placeResultData.formatted_address;
      this.newVictimizer.coordinates = `POINT(${addressData.longitude} ${addressData.latitude})`;

      this.$nextTick(() => {
        this.$refs.address.update(this.newVictimizer.address);
      });
    }
  }
};
</script>
