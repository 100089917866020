<template>
  <div
    v-loading="loading"
    class="list">
    <el-table
      :data="users"
      border
      style="width: 100%">
      <el-table-column
        prop="full_name"
        label="Nombre y Apellido" />
      <el-table-column
        prop="email"
        label="Email" />
      <el-table-column
        prop="username"
        label="Usuario" />
      <el-table-column
        prop="phone"
        label="Teléfono" />
      <el-table-column
        width="400"
        label="Distritos">
        <template slot-scope="scope">
          <span
            v-for="d in scope.row.districts"
            :key="d">
            {{ d }}<br >
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: configActions } = namespace("admin");
export default {
  name: "AdminUsers",
  data() {
    return {
      loading: false,
      users: []
    };
  },
  created() {
    this.loadUsers();
  },
  methods: {
    ...configActions(["fetchUsers"]),
    loadUsers() {
      this.loading = true;
      this.fetchUsers({ criteria: "", page: 1 })
        .then(response => {
          this.users = response["users"];
        })
        .catch(error => {
          console.log("error en fetchUsers", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
