<template>
  <el-form
    ref="form"
    :model="intervention"
    label-position="top"
    label-width="120px" >
    <div v-if="config">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Numero IPP">
            <el-input
              v-model="intervention.ipp_case_number"
              :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="Sujeto">
            <el-select
              v-model="intervention.subject_id"
              :disabled="interventionData.subject_id != null"
              filterable
              placeholder="Seleccionar sujeto"
              style="width: 100%;"
              @change="reloadConfig" >
              <el-option
                v-for="item in config.subjects"
                :key="item.id"
                :label="item.text"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="Etapa">
            <el-select
              v-model="intervention.stage"
              style="width: 100%;"
              @change="changeStage" >
              <el-option
                v-for="(stage, index) in stageList"
                :key="index"
                :value="index"
                :label="stage.name" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Intervención">
            <el-select
              v-model="intervention.intervention_type"
              style="width: 100%;"
              @change="changeInterventionType" >
              <el-option
                v-for="(option, index) in interventionList"
                :key="index"
                :value="index"
                :label="index" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Valoración">
            <el-select
              v-model="intervention.flow_step_id"
              style="width: 100%;"
              @change="changeFlowStep" >
              <el-option
                v-for="(option, index) in resultList"
                :key="option"
                :value="index"
                :label="option" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Publico">
            <el-switch v-model="intervention.public" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Lugar">
            <el-input v-model="intervention.place" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <span slot="label">Fecha de la Intervención</span>
            <el-date-picker
              v-model="intervention.intervention_date"
              :clearable="true"
              :format="dateFormat"
              :picker-options="datePickerOptions"
              style="width: 100%;"
              name="intervention_date"
              placeholder="Fecha" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item>
                <span slot="label">Hora Inicio</span>
                <el-time-select
                  v-model="intervention.start_hour"
                  :clearable="true"
                  :picker-options="timePickerStartOptions"
                  style="width: 100%;"
                  name="start_hour"
                  placeholder="Hora Inicio" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <span slot="label">Hora Fin</span>
                <el-time-select
                  v-model="intervention.end_hour"
                  :clearable="true"
                  :picker-options="timePickerEndOptions"
                  style="width: 100%;"
                  name="start_hour"
                  placeholder="Hora Fin" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Participantes">
            <el-select
              v-model="intervention.participants"
              multiple
              filterable
              placeholder="Seleccionar personas"
              style="width: 100%;">
              <el-option
                v-for="item in config.participants"
                :key="item"
                :value="item">
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                  <b>{{ item.split("-")[0] }}</b>
                  <span>{{ item.split("-")[1] }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Observaciones">
            <el-input
              v-model="intervention.observations"
              :rows="4"
              type="textarea"
              style="width: 100%;"
              placeholder="Observaciones" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Documentacion">
            <div
              v-if="intervention.document_url"
              :closable="false"
              class="el-alert el-alert--info is-light"
              style="margin-bottom: 10px; width: 100%; display: flex; justify-content: space-between; align-items: center;">
              <div>
                Esta intervencion ya tiene un documento asociado
                <a
                  :href="intervention.document_url"
                  style="color: #f56c6c; cursor: pointer;">
                  Bajar
                </a>
              </div>
              <a
                style="float: right; color: #f56c6c; cursor: pointer;"
                @click="showUpload = !showUpload">
                Cambiar documento
              </a>
            </div>
            <el-upload
              v-if="showUpload"
              ref="file"
              :auto-upload="false"
              :multiple="false"
              :on-change="setFile"
              action=""
              drag >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text"><em>seleccione un archivo</em></div>
              <div
                slot="tip"
                class="el-upload__tip">
                Solo se permiten documents del tipo docx/pdf y menores a 5Mb
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item>
            <el-button
              type="primary"
              @click="onSubmit()">
              Guardar
            </el-button>
            <el-button @click="cancel()">Volver</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: listValuesActions } = namespace("admin");
const {
  mapGetters: interventionGetters,
} = namespace("intervention");
import { clone, forEach } from "lodash";

export default {
  name: "InterventionForm",
  props: {
    interventionData: {
      type: Object,
      required: true
    }
  },
  data() {
    let intervention = {
      ipp_case_number: null,
      ipp_case_id: null,
      subject_id: null,
      stage: null,
      intervention_type: null,
      result: null,
      observations: null,
      intervention_date: null,
      start_hour: null,
      end_hour: null,
      place: null,
      public: null,
      document: null,
      flow_step_id: null,
      participants: []
    };
    return {
      intervention: intervention,
      subjects: [],
      definitionOptions: [],
      loadingIpp: false,
      dateFormat: "dddd dd, MMMM yyyy",
      ipp: null,
      showUpload: true,
      timePickerStartOptions: {
        start: "06:00",
        step: "00:15",
        end: "19:59"
      },
      timePickerEndOptions: {
        start: "06:00",
        step: "00:15",
        end: "19:59"
      },
      datePickerOptions: {
        disabledDate: time => {
          let now = new Date();
          return time.getTime() > now.getTime();
        }
      }
    };
  },
  computed: {
    ...interventionGetters(["config"]),
    stageList() {
      if (this.config.flow) return this.config.flow.stages;
      else return [];
    },
    interventionList() {
      if (this.config.flow && this.intervention.stage)
        return this.config.flow.stages[this.intervention.stage].interventions;
      else return [];
    },
    resultList() {
      if (this.config.flow && this.intervention.intervention_type)
        return this.config.flow.stages[this.intervention.stage].interventions[
          this.intervention.intervention_type
        ];
      else return [];
    }
  },
  created() {
    this.intervention = clone(this.interventionData);
    this.loadDefinitionList();
    if (this.intervention.document_url) this.showUpload = false;
  },
  methods: {
    ...listValuesActions(["fetchListValues"]),
    loadDefinitionList() {
      this.loading = true;
      this.fetchListValues({ list: "ipp_definitions" })
        .then(response => {
          this.definitionOptions = response;
        })
        .catch(error => {
          console.log("error en fetchListValues ipp_definitions", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reloadConfig(value) {
      this.$emit("reloadConfig", value);
    },
    onSubmit() {
      forEach(
        [
          "id",
          "ipp_case_number",
          "flow_name",
          "stage_name",
          "document_metadata",
          "subject_data",
          "created_at",
          "updated_at",
          "ipp_definition",
          "document_url",
          "has_document",
          "raw_participants"
        ],
        key => delete this.intervention[key]
      );
      this.$emit("save", this.intervention);
    },
    cancel() {
      this.$emit("cancel");
    },
    setFile() {
      let f = document.querySelector("[type=file]").files[0];
      console.log("Files", f);
      this.intervention.document = f;
    },
    changeStage() {
      this.intervention.intervention_type = null;
      this.changeInterventionType();
    },
    changeInterventionType() {
      this.intervention.flow_step_id = null;
      this.changeFlowStep(null);
    },
    changeFlowStep(value) {
      this.intervention.result = this.resultList[value];
    }
  }
};
</script>

<style lang="css">
.el-upload {
  width: 100%;
  .el-upload-dragger {
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}
.el-upload__tip {
  margin: 0px;
  text-align: center;
}
.el-upload-list {
  .el-upload-list__item {
    border: solid #eee 1px;
    padding: 10px 0px;
    font-size: 1.5rem;
    align-items: center;
  }
}
</style>
