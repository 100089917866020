import getters from "./getters";
import actions from "./actions";
import * as types from "../types";

const state = {
  derivations: []
};

const mutations = {
  [types.LOAD_DERIVATIONS_SUCCESS](state, data) {
    state.derivations = data.derivations;
  },
  [types.LOAD_DERIVATIONS_ERROR](state) {
    state.derivations = [];
  },
  [types.ADD_DERIVATION_SUCCESS](state, data) {
    state.derivations.push(data.derivation);
  },
  [types.UPDATE_DERIVATION_SUCCESS](state, data) {
    let current = state.derivations.find(derivation => {
      return derivation.id === data.derivation.id;
    });
    let currentIndex = state.derivations.indexOf(current);
    state.derivations.splice(currentIndex, 1, data.derivation);
  },
  [types.DELETE_DERIVATION_SUCCESS](state, data) {
    let current = state.derivations.find(derivation => {
      return derivation.id === data.derivation.id;
    });
    let currentIndex = state.derivations.indexOf(current);
    state.derivations.splice(currentIndex, 1);
  }
};

export default { state, mutations, getters, actions, namespaced: true };
