<template>
  <div class="page">
    <div class="page-header">
      <div class="controls">
        <el-input
          v-model="criteria"
          style="width: 300px"
          placeholder="Buscar..."
          @keyup.enter.native="search" >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search" />
        </el-input>
        <router-link
          v-is-disabled-for="['efector']"
          v-if="ipp_case_id"
          :to="{
            name: 'newIntervention',
            params: {
              ipp_id: ipp_case_id,
              subject_id: subject_id,
              ipp_number: ipp_case_number
            }
          }"
        >
          <el-button type="primary">
            <i class="el-icon-plus"></i> Intervencion
          </el-button>
        </router-link>
      </div>
      <h2>
        <router-link
          :to="{
            name: 'ipp',
            params: { id: ipp_case_id, subject_id: subject_id }
          }"
        >
          <span
            v-if="ipp_case_number"
            class="sep">
            <i class="el-icon-back" />
          </span>
        </router-link>
        IPPs
        <span class="sep"><i class="el-icon-arrow-right"/></span>
        Intervenciones
      </h2>
    </div>
    <div class="page-content">
      <div class="list">
        <el-table
          :data="interventions"
          style="width: 100%">
          <el-table-column
            prop="ipp_case_number"
            label="IPP">
            <template slot-scope="scope">
              <div>
                <router-link :to="{ name: 'ipp', params: { id: scope.row.ipp_case_id } }">
                  {{ scope.row.ipp_case_number }}
                </router-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="subject_data"
            label="Sujeto" />
          <el-table-column label="Fecha">
            <template slot-scope="scope">
              <div>
                {{ scope.row.intervention_date | moment("ddd DD MMM YYYY") | capitalize }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="stage_name"
            label="Etapa" />
          <el-table-column
            prop="intervention_type"
            label="Intervención" />
          <el-table-column
            prop="result"
            label="Resultado" />
          <el-table-column
            prop="ipp_definition"
            label="Estado IPP" />
          <el-table-column width="180">
            <template slot-scope="scope">
              <router-link :to="{ name: 'intervention', params: { id: scope.row.id } }" >
                <el-button
                  size="mini"
                  type="primary">
                  Ver
                </el-button>
              </router-link>
              <a
                v-if="scope.row.has_document"
                :href="scope.row.document_url"
                class="el-button el-button--default el-button--mini" >
                Documento
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-footer">
      <el-pagination
        :total="pagination.totalEntries"
        :current-page="pagination.currentPage"
        :page-count="pagination.totalPages"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizes"
        background
        layout="prev, pager, next, ->, jumper, sizes, total"
        @size-change="changeSize"
        @current-change="changePage" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: interventionActions } = namespace("intervention");

export default {
  name: "Interventions",
  data() {
    return {
      loading: false,
      ipp_case_number: this.$route.params.ipp_number,
      ipp_case_id: this.$route.params.ipp_id,
      subject_id: this.$route.params.subject_id,
      interventions: [],
      criteria: "",
      pagination: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        offset: 0,
        currentPage: 1,
        totalEntries: 0,
        totalPages: 0
      },
      columns: []
    };
  },
  created() {
    this.loadInterventions();
  },
  methods: {
    ...interventionActions(["fetchInterventions"]),
    loadInterventions() {
      this.loading = true;
      this.fetchInterventions({
        criteria: this.criteria,
        ipp_id: this.ipp_case_id,
        subject_id: this.subject_id,
        page: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      })
        .then(response => {
          this.loading = false;
          this.interventions = response.interventions;
          this.pagination.pageSize = response.meta.per_page;
          this.pagination.offset = response.meta.offset;
          this.pagination.currentPage = response.meta.current_page;
          this.pagination.totalEntries = response.meta.total_entries;
          this.pagination.totalPages = response.meta.total_pages;
          this.pagination.previousPage = response.meta.previous_page;
          this.pagination.nextPage = response.meta.next_page;
        })
        .catch(error => {
          console.log("error en fetchInterventions", error);
        });
    },
    search() {
      console.log("search");
      this.onSearch(this.criteria);
    },
    changePage(value) {
      this.pagination.currentPage = value;
      this.loadInterventions();
    },
    changeSize(value) {
      this.pagination.pageSize = value;
      this.loadInterventions();
    },
    onSearch(value) {
      this.criteria = `${value}`.toLowerCase();
      this.pagination.currentPage = 1;
      this.loadInterventions();
    }
  }
};
</script>
