<template>
  <div>
    <div class="user-info">
      <div class="name">
        <router-link :to="{ path: '/profile' }">
          <div>{{ user.first_name }} {{ user.last_name }}</div>
          <small style="font-size: 12px;">{{ user.email }}</small>
        </router-link>
      </div>
      <div class="user-logout">
        <a
          class="logout"
          @click="openLogoutModal()">
          <span>Cerrar Sesion</span>
        </a>
      </div>
    </div>
    <br >
    <div class="currentJurisdiction">
      <div style="font-size: 0.85rem;color: #f9f9f9; width: 80%; margin:auto;">
        <div
          class="image"
          style="flex: 1;">
          <img
            :src="getImgUrl(user.jurisdiction.name)"
            style="height: 50px;">
        </div>
        <div style="display: flex; text-align: center; display: flex; text-align: center;">
          {{ user.jurisdiction.name }}
        </div>
        <div
          v-if="canChangeJurisdiction"
          class="change">
          <a @click="setDefaultModal = true">Cambiar</a>
        </div>
      </div>
    </div>
    <br >
    <search-button @open-search="openSearchModal()" />
    <br >
    <div class="menu-items">
      <side-menu-item
        :route-name="{ name: 'dashboard' }"
        :is-main="true"
        tooltip-content="Panel inicio"
        display-name="Inicio"
        class-name="menu-item dashboard" />
      <side-menu-item
        v-is-enabled-for="['admin']"
        :route-name="{ path: '/admin/usuarios' }"
        :is-main="true"
        tooltip-content="Administracion"
        display-name="Administracion"
        class-name="menu-item dashboard admin" />
      <h3>
        <i class="icono-arg-declaracio-n-jurada-declaracion-jurada-rectificativa"></i>
        IPP
      </h3>
      <side-menu-item
        :route-name="{ name: 'newIpp' }"
        :is-main="false"
        tooltip-content="Ingresar nuevo IPP"
        display-name="Nuevo IPP"
        class-name="menu-item" />
      <side-menu-item
        :route-name="{ name: 'ipps' }"
        :is-main="false"
        tooltip-content="Listado de IPP"
        display-name="IPPs / Expedientes"
        class-name="menu-item" />
      <side-menu-item
        :route-name="{ name: 'interventions' }"
        :is-main="false"
        tooltip-content="Listado de intervenciones"
        display-name="Intervenciones"
        class-name="menu-item" />
      <div v-is-disabled-for="['efector']">
        <h3><i class="icono-arg-codigo-genetico"></i> Red</h3>
        <side-menu-item
          :route-name="{ path: '/red/organizaciones' }"
          :is-main="false"
          tooltip-content="Organismos e instituciones"
          display-name="Organizaciones"
          class-name="menu-item" />
        <side-menu-item
          :route-name="{ path: '/red/programas' }"
          :is-main="false"
          tooltip-content="Programas de apoyo"
          display-name="Programas"
          class-name="menu-item" />
        <side-menu-item
          :route-name="{ path: '/red/referentes' }"
          :is-main="false"
          tooltip-content="Referentes"
          display-name="Referentes"
          class-name="menu-item" />
      </div>
    </div>

    <el-dialog
      :visible.sync="logoutModal"
      width="25%">
      <div style="text-align: center; padding: 10px; color: #000; font-size: 20px;">Seguro que queres salir?</div>
      <div slot="footer">
        <div style="text-align: right;">
          <el-button
            type="text"
            size="mini"
            @click="logoutModal = false" >
            Cancelar
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="confirmLogout()">
            Salir
          </el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="setDefaultModal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="Cambiar de Departamento Judicial" >
      <div style="padding: 20px 0px;">
        <el-select
          v-model="defaultJurisdiction"
          clearable
          placeholder="Seleccione otro Departamento Judicial"
          style="width: 100%;" >
          <el-option
            v-for="item in user.jurisdiction_list"
            :key="item.value"
            :label="item.name"
            :value="item.id" >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">
              {{ item.role }}
            </span>
            <span
              v-if="item.default"
              style="float: right; color: #27be12; margin: 0px 10px; font-size: 15px;">
              <i class="el-icon-check"></i>
            </span>
          </el-option>
        </el-select>
        <div style="color: #F56C6C; margin: 10px 3px;">
          <el-switch
            v-model="setJurDefault"
            active-color="#13ce66"
            inactive-text="Cambiar de departamento"
            active-text="Cambiar de departamento y guardar como default"
            style="display: block" />
        </div>
        <hr >
        <div slot="footer">
          <div style="text-align: right;">
            <el-button
              :disabled="!defaultJurisdiction && !user.jurisdiction.id"
              @click="setDefaultModal = false" >
              Cancelar
            </el-button>
            <el-button
              type="primary"
              @click="setDefaultJurisdiction()">
              Guardar
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchButton from "./SearchButton";
import SideMenuItem from "./SideMenuItem";
import { EventBus } from "@/components/EventBus";

import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: authActions } = namespace("auth");

export default {
  name: "MainSideMenu",
  components: { SearchButton, SideMenuItem },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      setDefaultModal: false,
      defaultJurisdiction: null,
      setJurDefault: false,
      logoutModal: false
    };
  },
  computed: {
    canChangeJurisdiction() {
      return this.user.jurisdiction_list.length > 1;
    }
  },
  methods: {
    ...authActions(["setJurisdiction"]),
    openSearchModal() {
      this.$emit("open-search-modal");
    },
    openLogoutModal() {
      this.logoutModal = true;
    },
    confirmLogout() {
      this.logoutModal = false;
      this.$emit("confirm-logout");
    },
    setDefaultJurisdiction() {
      this.setJurisdiction({
        jurisdictionUserId: this.defaultJurisdiction,
        isDefault: this.setJurDefault
      })
        .then(() => {
          this.setDefaultModal = false;
          this.defaultJurisdiction = null;
          this.setJurDefault = false;
          if (this.$route.name === "dashboard") {
            EventBus.$emit("reload-ipps");
          } else {
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch(error => {
          console.log("Error", error);
        });
    },
    getImgUrl(jurisdiction_name) {
      //console.log("Jurisdiction name", jurisdiction_name);
      if (jurisdiction_name == "Departamento Judicial de San Isidro") {
        return require("../../assets/images/san-isidro-basic.png");
      } else if (jurisdiction_name == "Departamento Judicial de Pergamino") {
        return require("../../assets/images/pergamino-basic.png");
      }
      return require("../../assets/images/logo-basic.png");
    }
  }
};
</script>

<style lang="css">
.el-aside.main-side-menu {
  background-color: rgb(51, 62, 76);
  color: rgb(201, 196, 200);
  transition: all 0.1s;
}

.user-info {
  text-align: center;
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0px;
}

.user-info .name a,
.user-info .name small {
  color: #fff;
}

.currentJurisdiction {
  text-align: center;
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.currentJurisdiction .change {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
}
.currentJurisdiction .change a {
  color: #f56c6c;
}

.currentJurisdiction .jur-mark {
  color: #fff;
  float: left;
  background-color: #33b65f;
  border: solid 1px #33b65f;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  padding: 2px;
  margin: 0px 10px 0px -10px;
}

/* Menu items styles */
.menu-items {
  margin: 10px 0px;
}
.menu-items a.menu-item {
  color: rgb(201, 196, 200);
  display: block;
  padding: 10px;
}
.menu-items a.menu-item:hover {
  background: rgb(103, 111, 122);
  color: #fff;
}
.menu-items a.menu-item.router-link-exact-active {
  background: rgb(112, 151, 196);
  color: #fff;
}
.menu-items a.menu-item.admin.router-link-exact-active,
.menu-items a.menu-item.admin.router-link-active {
  background: rgb(103, 111, 122);
  color: #fff;
}
.menu-items a.menu-item span {
  padding-left: 35px;
}

.menu-items h3 i,
.menu-items .menu-item.dashboard i {
  float: left;
  margin-right: 10px;
  margin-top: -2px;
  font-size: 17px;
}
.menu-items .menu-item.dashboard span {
  padding-left: 0px;
  font-size: 17px;
}
.menu-items .menu-item.dashboard,
.menu-items .menu-item.dashboard.admin {
  margin: 0px;
  padding: 10px 0px 10px 10px;
}
.menu-items h3 {
  margin: 10px 0px;
  padding: 10px 0px 10px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 17px;
}

.user-logout {
  padding-top: 5px;
}
.user-logout .logout {
  color: #f56c6c !important;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.no-connection {
  background: #f1f1f1;
  color: #333;
}
</style>
