<template>
  <div class="page">
    <div class="page-header">
      <router-link
        :to="{ name: 'derivations', params: { id: subjectId } }"
        style="float: left; margin: 5px 20px 5px 0px; font-size: 20px;">
        <i class="el-icon-back"></i>
      </router-link>
      <h2>Editar derivación</h2>
    </div>
    <div
      class="page-content"
      style="padding: 20px;">
      <derivation-form
        ref="derivationForm"
        :derivation="derivation"
        @save="submit"
        @reset="loadDerivation" />
    </div>
  </div>
</template>

<script>
import { clone } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
import DerivationForm from "./DerivationForm";
const { mapActions: derivationActions } = namespace("derivation");

export default {
  name: "EditDerivation",
  components: { DerivationForm },
  data() {
    return {
      subjectId: null,
      derivationId: null,
      derivation: {}
    };
  },
  created() {
    console.log("params received", this.$route.params);
    this.subjectId = clone(this.$route.params.id);
    this.derivationId = clone(this.$route.params.derivation_id);
    this.loadDerivation();
  },
  methods: {
    ...derivationActions(["fetchDerivation", "updateDerivation"]),
    loadDerivation() {
      this.loading = true;
      this.fetchDerivation({
        subjectId: this.subjectId,
        derivationId: this.derivationId
      })
        .then(response => {
          this.derivation = response.derivation;
          this.$refs.derivationForm.loadDerivables(
            this.derivation.derivable_type
          );
        })
        .catch(error => {
          console.log("error en fetchDerivation", error);
        });
      this.loading = false;
    },
    submit() {
      this.loading = true;
      let derivationData = {
        date_from: this.derivation.date_from,
        reason: this.derivation.reason,
        state: this.derivation.state,
        type: this.derivation.type,
        derivable_type: this.derivation.derivable_type,
        derivable_id: this.derivation.derivable_id,
        next_control: this.derivation.next_control
      };
      this.updateDerivation({
        subjectId: this.subjectId,
        derivationId: this.derivation.id,
        payload: derivationData
      })
        .then(() => {
          this.$message.success("Derivación actualizada");
          this.$router.push({
            name: "derivations",
            params: { id: this.subjectId }
          });
        })
        .catch(error => {
          console.log("error grabando derivación", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
#programs-data {
  background: #fff;
  height: 100%;
}

.data {
  padding: 0px 20px;
}
</style>
