<template>
  <el-form
    ref="form"
    :model="referent"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item label="Nombres">
          <el-input v-model="referent.firstname" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Apellidos">
          <el-input v-model="referent.lastname" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Tipo">
          <el-select
            v-model="referent.ref_type"
            filterable
            style="width: 100%"
            placeholder="Select"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Telefono">
          <el-input v-model="referent.phone" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Celular">
          <el-input v-model="referent.cellphone" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Email">
          <el-input v-model="referent.email" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <span slot="label">Dirección</span>
          <div class="el-input">
            <vue-google-autocomplete
              id="map"
              ref="address"
              :country="['ar', 'br']"
              class="el-input__inner"
              placeholder="Direccion"
              autocomplete="false"
              style="width: 100%;"
              @placechanged="getAddressData"
            />
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <!--     
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="Cargo">
          <el-select
            v-model="referent.position"
            filterable
            style="width: 100%"
            placeholder="Select">
            <el-option
              v-for="item in positionOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Rubros">
          <el-select
            v-model="referent.areas"
            multiple
            filterable
            style="width: 100%"
            placeholder="Select">
            <el-option
              v-for="item in areaOptions"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="Zonas">
      <el-select
        v-model="referent.zoneids"
        multiple
        filterable
        style="width: 100%"
        placeholder="Select">
        <el-option
          v-for="item in zoneOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"/>
      </el-select>
    </el-form-item>
    -->
    <hr >
    <el-form-item>
      <el-button
        type="success" 
        @click="onSubmit">Guardar</el-button>
      <el-button
        type="danger" 
        @click="onReset">Recargar</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import VueGoogleAutocomplete from "../Shared/VueGoogleAutocomplete";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: listValuesActions } = namespace("admin");
const { mapActions: zoneActions } = namespace("zone");

export default {
  name: "ReferentForm",
  components: { VueGoogleAutocomplete },
  props: {
    referent: { type: Object, required: true }
  },
  data() {
    return {
      typeOptions: [
        { label: "Positivo", value: "positive" },
        { label: "Articulación", value: "articulative" }
      ],
      areaOptions: [],
      positionOptions: [],
      zoneOptions: []
    };
  },
  created() {
    this.loadReferentAreas();
    this.loadReferentPositions();
    this.loadZones();
    this.$nextTick(() => {
      this.$refs.address.update(this.referent.address);
    });
  },
  methods: {
    ...listValuesActions(["fetchListValues"]),
    ...zoneActions(["fetchZones"]),
    loadReferentAreas() {
      this.loading = true;
      this.fetchListValues({ list: "referent_areas" })
        .then(response => {
          this.areaOptions = response;
        })
        .catch(error => {
          console.log("error en fetchListValues referent_areas", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadReferentPositions() {
      this.loading = true;
      this.fetchListValues({ list: "referent_positions" })
        .then(response => {
          this.positionOptions = response;
        })
        .catch(error => {
          console.log("error en fetchListValues referent_positions", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadZones() {
      this.loading = true;
      this.fetchZones()
        .then(response => {
          this.zoneOptions = response;
        })
        .catch(error => {
          console.log("error en fetchZones", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$emit("save");
    },
    onReset() {
      this.$emit("reset");
    },
    getAddressData(addressData, placeResultData) {
      console.log(addressData);
      this.referent.address = placeResultData.formatted_address;
      this.referent.coordinates = `POINT(${addressData.longitude} ${addressData.latitude})`;

      this.$nextTick(() => {
        this.$refs.address.update(this.referent.address);
      });
    }
  }
};
</script>
