import store from "../store";

export const isEnabledFor = {
  bind: function(el, bindings) {
    let roles = bindings.value;
    let user = store.getters["auth/user"];
    //console.log("user.jurisdiction", user.jurisdiction);
    if (
      user.jurisdiction &&
      user.jurisdiction.role &&
      roles.indexOf(user.jurisdiction.role) === -1
    ) {
      el.style = "display: none";
      el.remove();
    }
  }
};
