/* eslint-disable no-unused-vars */
// import * as types from "../types";
import axios from "axios";

const fetchDerivations = ({ commit }, { subjectId }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`subjects/${subjectId}/derivations`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const fetchDerivation = ({ commit }, { subjectId, derivationId }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`subjects/${subjectId}/derivations/${derivationId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const addDerivation = ({ commit }, { subjectId, payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`subjects/${subjectId}/derivations`, { derivation: payload })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const updateDerivation = ({ commit }, { subjectId, derivationId, payload }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`subjects/${subjectId}/derivations/${derivationId}`, {
        derivation: payload
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const deleteDerivation = ({ commit }, { subjectId, derivationId }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`subjects/${subjectId}/derivations/${derivationId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default {
  fetchDerivations,
  fetchDerivation,
  addDerivation,
  updateDerivation,
  deleteDerivation
};
