<template>
  <div class="ipp-form-container">
    <div class="ipp-step-header">
      <el-button
        type="primary"
        style="float: left"
        @click="goBack()">
        <fw-icon icon="chevron-left" />
        Volver
      </el-button>
      Victimarios
      <div style="float: right">
        <el-button
          :disabled="!valid"
          type="primary"
          @click="goNext()">
          Siguiente
          <fw-icon icon="chevron-right" />
        </el-button>
        <el-button
          :disabled="valid"
          type="primary"
          @click="setAndShowAlert()">
          Continuar sin cargar victimarios
          <fw-icon icon="chevron-right" />
        </el-button>
      </div>
    </div>
    <div class="ipp-step-inner">
      <h3 style="margin-top: 50px;">Agregar Victimario</h3>
      <victimizer-inputs
        :ipp-form-victimizers="ippFormVictimizers"
        :request-local="true"
      />
      <hr >
      <h3 style="margin-top: 50px;">Victimarios agregados al IPP</h3>
      <el-table
        :data="ippFormVictimizers"
        style="width: 100%">
        <el-table-column label="Nombre">
          <template slot-scope="scope">
            {{ scope.row.firstname }} {{ scope.row.lastname }}
          </template>
        </el-table-column>
        <el-table-column label="DNI">
          <template slot-scope="scope">
            {{ scope.row.document_number || "--------" }}
          </template>
        </el-table-column>
        <el-table-column label="Direccion">
          <template slot-scope="scope">
            {{ scope.row.address || "--------" }}
          </template>
        </el-table-column>
        <el-table-column label="Genero">
          <template slot-scope="scope">
            {{ genderDescription(scope.row.gender) }}
          </template>
        </el-table-column>
        <el-table-column width="70">
          <template slot-scope="scope">
            <a
              href="#"
              @click="removeVictimizer(scope.row)">
              <i class="el-icon-delete" />
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :visible.sync="showAlert"
      title="¡Atencion!"
      width="30%"
      center>
      <span style="text-align: center;">
        {{ alertMessage }}
      </span>
      <span
        slot="footer"
        class="dialog-footer">
        <el-button @click="closeAlert()">
          Cancelar
        </el-button>
        <el-button
          type="primary"
          @click="goNextWithoutVictimizers()">
          Continuar
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VictimizerInputs from "./VictimizerInputs";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapGetters: ippGetters, mapActions: ippActions } = namespace("ipp");

export default {
  name: "PeopleInvolved",
  components: { VictimizerInputs },
  data() {
    return {
      showForm: false,
      showAlert: false,
      alertMessage: null
    };
  },
  computed: {
    ...ippGetters(["ippFormVictimizers"]),
    valid() {
      return this.ippFormVictimizers.length > 0;
    }
  },
  methods: {
    ...ippActions([
      "setIppVictimizers",
      "checkDNI",
      "addPerson",
      "removePerson"
    ]),
    goBack() {
      this.$emit("next", 0);
    },
    goNext() {
      if (this.valid) {
        this.$emit("next", 2);
      }
    },
    closeAlert() {
      this.alertMessage = false;
      this.showAlert = false;
    },
    setAndShowAlert() {
      this.alertMessage = "¿Está seguro de continuar sin cargar victimarios?";
      this.showAlert = true;
    },
    goNextWithoutVictimizers() {
      this.closeAlert();
      this.$emit("next", 2);
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    removeVictimizer(vict) {
      this.removePerson({ personId: vict.id, role: "victimizers" }).then(() => {
        console.log("Victimario removido");
      });
    },
    genderDescription(value) {
      let genderDesc = "";
      switch (value) {
        case "Masc":
          genderDesc = "Masculino";
          break;
        case "Fem":
          genderDesc = "Femenino";
          break;
        case "Otro":
          genderDesc = "Otro";
          break;
        default:
          genderDesc = "S/D";
      }
      return genderDesc;
    }
  }
};
</script>

<style>
ul.victimizer-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.victimizer-row {
  height: 3em;
  line-height: 2.5em;
}
.victimizer-row .muted {
  color: #999999;
}
.pull-right {
  float: right;
}
</style>
