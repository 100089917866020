<template>
  <div class="page">
    <div
      class="page-header"
      style="text-align: center;">
      <h2>Bienvenido a JJR</h2>
    </div>
    <div class="page-content">
      <el-row :gutter="10" style="margin-top: 10px;">
        <el-col :span="12">
          <el-card>
            <div
              slot="header"
              class="clearfix">
              <h3 class="dashboard-title">IPPs asignados a mí</h3>
            </div>
            <div v-if="myIpps.length > 0">
              <div
                v-loading="loadingMine"
                class="list">
                <el-table
                  :data="myIpps"
                  style="width: 100%">
                  <el-table-column
                    prop="ipp_number"
                    label="Nro IPP">
                    <template slot-scope="scope">
                      <router-link :to="{ name: 'ipp', params: { id: scope.row.id } }">
                        {{ scope.row.ipp_number }}
                      </router-link>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="definition"
                    label="Definicion"
                    width="220" />
                  <el-table-column label="Estado">
                    <template slot-scope="scope">
                      {{ scope.row.case_state.name }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <br >
              <el-pagination
                :total="pagination.totalEntries"
                :current-page="pagination.currentPage"
                :page-count="pagination.totalPages"
                :page-size="pagination.pageSize"
                :page-sizes="pagination.pageSizes"
                background
                layout="prev, pager, next, ->, jumper, sizes, total"
                @size-change="changeSize"
                @current-change="changePage" />
            </div>
            <div
              v-else
              style="padding: 20px; color: #777; text-align: center;">
              No hay informacion disponible
            </div>
          </el-card>
        </el-col>
        <el-col
          v-is-enabled-for="['admin', 'coordinador', 'tecnico']"
          :span="12">
          <el-card>
            <div
              slot="header"
              class="clearfix">
              <h3 class="dashboard-title">IPPs pendientes de asignar</h3>
            </div>
            <div v-if="pendingIpps.length > 0">
              <div
                v-loading="loadingPending"
                class="list">
                <el-table
                  :data="pendingIpps"
                  style="width: 100%">
                  <el-table-column
                    prop="ipp_number"
                    label="Nro IPP"
                    width="170" />
                  <el-table-column label="Estado">
                    <template slot-scope="scope">
                      {{ scope.row.case_state.name }}
                    </template>
                  </el-table-column>
                  <el-table-column width="100">
                    <template slot-scope="scope">
                      <router-link :to="{ name: 'ipp', params: { id: scope.row.id } }">
                        <el-button
                          size="mini"
                          type="primary">
                          Ver
                        </el-button>
                      </router-link>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div
              v-else
              style="padding: 20px; color: #777; text-align: center;">
              No hay informacion disponible
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { EventBus } from "./EventBus";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: ippActions } = namespace("ipp");
const { mapGetters: authGetters } = namespace("auth");

export default {
  name: "Dashboard",
  data() {
    return {
      loadingMine: false,
      loadingPending: false,
      myIpps: [],
      pendingIpps: [],
      pendingIppRoles: ['admin','coordinador','tecnico'],
      pagination: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        offset: 0,
        currentPage: 1,
        totalEntries: 0,
        totalPages: 0
      },
      columns: []
    };
  },
  computed: {
    ...authGetters(["user"])
  },
  created() {
    this.loadMyIPPs();
    if (this.pendingIppRoles.includes(this.user.jurisdiction.role)) {
      this.loadPendingIPPs();
    }
    EventBus.$on("reload-ipps", () => {
      this.loadMyIPPs();
      this.loadPendingIPPs();
    });
  },
  methods: {
    ...ippActions(["fetchIpps"]),
    loadMyIPPs() {
      this.loadingMine = true;
      this.fetchIpps({
        field: "ipp",
        criteria: "",
        page: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        active: true,
        own: "S"
      })
        .then(response => {
          this.myIpps = response.ipp_cases;
          this.pagination.pageSize = response.meta.per_page;
          this.pagination.offset = response.meta.offset;
          this.pagination.currentPage = response.meta.current_page;
          this.pagination.totalEntries = response.meta.total_entries;
          this.pagination.totalPages = response.meta.total_pages;
          this.pagination.previousPage = response.meta.previous_page;
          this.pagination.nextPage = response.meta.next_page;
        })
        .catch(error => {
          console.log("error en fetchIpps", error);
        });
      this.loadingMine = false;
    },
    loadPendingIPPs() {
      this.loadingPending = true;
      this.fetchIpps({
        field: "ipp",
        page: 1,
        pageSize: 1000,
        assigned: "N",
        criteria: "",
        active: true
      })
        .then(response => {
          this.pendingIpps = response.ipp_cases;
        })
        .catch(error => {
          console.log("error en fetchIpps", error);
        });
      this.loadingPending = false;
    },
    changePage(value) {
      this.pagination.currentPage = value;
      this.loadMyIPPs();
    },
    changeSize(value) {
      this.pagination.pageSize = value;
      this.loadMyIPPs();
    }
  }
};
</script>

<style>
#dashboard {
  text-align: center;
  padding: 20px;
}
#dashboard a {
  text-decoration: none;
  color: #888;
}
.option {
  font-size: 20px;
}
#dashboard img {
  height: 100px;
}
.dashboard-title {
  padding: 0 20px;
  text-align: center;
  margin: 5px 0px;
}
</style>
