<template>
  <el-dialog
    :visible.sync="showForm"
    title="Buscador Avanzado"
    width="50%">
    <div>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select
            v-model="sourceModel"
            placeholder="Seleccionar..."
            style="width: 100%;"
            @change="updateInput()">
            <el-option
              v-for="(value, key) in fieldSelector"
              :key="key"
              :label="value"
              :value="key"/>
          </el-select>
        </el-col>
        <el-col :span="18">
          <vue-simple-suggest
            ref="suggestions"
            :list="filterOptions"
            :destyled="true"
            :list-is-request="true"
            :styles="autoCompleteStyle"
            @select="showSelect">
            <el-input
              ref="searchSuggenstions"
              v-model="query"
              :placeholder="'Buscar...'"
              :disabled="!sourceModel"
              suffix-icon="el-icon-search"
              clearable
              style="width: 100%;"/>
            <div
              v-if="loading"
              style="text-align: center; padding: 10px">
              <i class="el-icon-loading"></i> Buscando...
            </div>
            <div
              slot="suggestion-item"
              slot-scope="{ suggestion, query }">
              <div class="my-suggestion-list-item">
                <span v-html="boldenSuggestion(suggestion, query)"></span>
                <div class="extra">
                  <span>{{ suggestion.definition }}</span>
                </div>
              </div>
            </div>
          </vue-simple-suggest>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: ippActions } = namespace("ipp");
export default {
  name: "MainSearch",
  data() {
    return {
      showForm: false,
      loading: false,
      sourceModel: "ipp",
      query: "",
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "el-input",
        defaultInput: "el-input__inner",
        suggestions: "my-suggestion-list",
        suggestionItem: "my-suggestion-list-item"
      },
      fields: {
        ipp: "ipp_number",
        victimizer: "victimizers",
        victim: "victims"
      },
      fieldSelector: {
        ipp: "IPP",
        victimizer: "Victimario",
        victim: "Víctima"
      }
    };
  },
  methods: {
    ...ippActions(["fetchIpps"]),
    openSearch() {
      this.showForm = true;
      setTimeout(() => {
        let ref = this.$refs.searchSuggenstions;
        ref.focus();
      }, 100);
    },
    updateInput() {
      this.$refs.suggestions.research();
    },
    filterOptions(value) {
      if (value.length <= 3) return;
      return new Promise((resolve, reject) => {
        this.loadIPPs(value)
          .then(response => {
            this.loading = false;
            let ipps = response.ipp_cases;
            resolve(ipps);
          })
          .catch(error => {
            console.log("error en loadIPPs", error);
            reject();
          });
      });
    },
    boldenSuggestion(suggestion, query) {
      let field = this.fields[this.sourceModel];
      return suggestion[field].replace(
        new RegExp("(.*?)(" + query + ")(.*?)", "gi"),
        "$1<b class='red'>$2</b>$3"
      );
    },
    showSelect(value) {
      this.showForm = false;
      this.query = "";
      if (value) {
        this.$router.push({ name: "ipp", params: { id: value.id } });
      }
    },
    loadIPPs() {
      this.loading = true;
      let params = {
        field: this.sourceModel,
        criteria: this.query,
        page: 1,
        pageSize: 100
      };
      return this.fetchIpps(params);
    }
  }
};
</script>

<style>
.el-dialog__body {
  padding: 20px;
}

.my-suggestion-list {
  margin-top: 10px;
  font-size: 15px;
  background: #fafafa;
  color: #333;
  z-index: 1000;
  max-height: 400px;
  overflow-y: scroll;
}
.my-suggestion-list .my-suggestion-list-item {
  padding: 10px;
  margin: 0px;
  cursor: pointer;
}
.my-suggestion-list .my-suggestion-list-item:hover {
  background-color: #409eff;
  color: #fff;
}
.my-suggestion-list .my-suggestion-list-item:hover .extra,
.my-suggestion-list .my-suggestion-list-item:hover .red {
  color: #f9f9f9;
}
.my-suggestion-list .my-suggestion-list-item .extra {
  float: right;
  color: #888;
  margin-left: 5px;
}
.red {
  color: #f56c6c;
}
</style>
