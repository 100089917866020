import Organizations from "@/components/Organization/Organizations";
import Organization from "@/components/Organization/Organization";
import EditOrganization from "@/components/Organization/EditOrganization";
import NewOrganization from "@/components/Organization/NewOrganization";

const organization_routes = [
  {
    path: "organizaciones",
    name: "organizations",
    component: Organizations,
    meta: { requireAuth: true, requireAdmin: false }
  },
  {
    path: "organizaciones/nuevo",
    name: "newOrganization",
    component: NewOrganization,
    meta: { requireAuth: true, requireAdmin: true }
  },
  {
    path: "organizaciones/:id",
    name: "organization",
    component: Organization,
    meta: { requireAuth: true, requireAdmin: false }
  },
  {
    path: "organizaciones/:id/editar",
    name: "editOrganization",
    component: EditOrganization,
    meta: { requireAuth: true, requireAdmin: true }
  }
];

export default organization_routes;
