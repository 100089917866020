export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const SET_JURISDICTION = "SET_JURISDICTION";

export const CREATE_PASSWORD_RESET_REQUEST_SUCCESS = "CREATE_PASSWORD_RESET_REQUEST_SUCCESS";
export const CREATE_PASSWORD_RESET_REQUEST_ERROR = "CREATE_PASSWORD_RESET_REQUEST_ERROR";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const LOAD_REFERENTS_SUCCESS = "LOAD_REFERENTS_SUCCESS";
export const LOAD_REFERENTS_ERROR = "LOAD_REFERENTS_ERROR";
export const ADD_REFERENT_SUCCESS = "ADD_REFERENT_SUCCESS";
export const UPDATE_REFERENT_SUCCESS = "UPDATE_REFERENT_SUCCESS";

export const LOAD_ORGANIZATIONS_SUCCESS = "LOAD_ORGANIZATIONS_SUCCESS";
export const LOAD_ORGANIZATIONS_ERROR = "LOAD_ORGANIZATIONS_ERROR";
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";

export const LOAD_PROGRAMS_SUCCESS = "LOAD_PROGRAMS_SUCCESS";
export const LOAD_PROGRAMS_ERROR = "LOAD_PROGRAMS_ERROR";
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";

export const LOAD_CONFIG_SUCCESS = "LOAD_CONFIG_SUCCESS";
export const LOAD_LOV_SUCCESS = "LOAD_LOV_SUCCESS";

export const LOAD_ZONES_SUCCESS = "LOAD_ZONES_SUCCESS";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "LOAD_USERS_ERROR";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

// IPP DATA
export const IPP_SET_BASE = "IPP_SET_BASE";
export const LOAD_IPP_DATA = "LOAD_IPP_DATA";
export const RESET_IPP = "RESET_IPP";

export const ADD_PERSON = "ADD_PERSON";
export const REMOVE_PERSON = "REMOVE_PERSON";

export const ADD_POLICE_STATION = "ADD_POLICE_STATION";
export const REMOVE_POLICE_STATION = "REMOVE_POLICE_STATION";

export const ADD_CRIME = "ADD_CRIME";
export const REMOVE_CRIME = "REMOVE_CRIME";

export const ADD_OBSERVATION = "ADD_OBSERVATION";
export const REMOVE_OBSERVATION = "REMOVE_OBSERVATION";

export const ADD_DEFINITION = "ADD_DEFINITION";
export const REMOVE_DEFINITION = "REMOVE_DEFINITION";

// SUBJECTS DATA
export const LOAD_SUBJECTS_SUCCESS = "LOAD_SUBJECTS_SUCCESS";
export const LOAD_SUBJECTS_ERROR = "LOAD_SUBJECTS_ERROR";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";
export const SUBJECT_SQL_LISTS = "SUBJECT_SQL_LISTS";

export const LOAD_DERIVATIONS_SUCCESS = "LOAD_DERIVATIONS_SUCCESS";
export const LOAD_DERIVATIONS_ERROR = "LOAD_DERIVATIONS_ERROR";
export const ADD_DERIVATION_SUCCESS = "ADD_DERIVATION_SUCCESS";
export const UPDATE_DERIVATION_SUCCESS = "UPDATE_DERIVATION_SUCCESS";
export const DELETE_DERIVATION_SUCCESS = "DELETE_DERIVATION_SUCCESS";

// INTERVENTIONS
export const LOAD_INTERVENTIONS_SUCCESS = "LOAD_INTERVENTIONS_SUCCESS";
export const LOAD_INTERVENTIONS_ERROR = "LOAD_INTERVENTIONS_ERROR";
export const ADD_INTERVENTION_SUCCESS = "ADD_INTERVENTION_SUCCESS";
export const UPDATE_INTERVENTION_SUCCESS = "UPDATE_INTERVENTION_SUCCESS";
export const DELETE_INTERVENTION_SUCCESS = "DELETE_INTERVENTION_SUCCESS";
export const LOAD_INTERVENTIONS_CONFIG = "LOAD_INTERVENTIONS_CONFIG";
export const CHECK_SUCCESS = "CHECK_SUCCESS";
