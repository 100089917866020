<template>
  <div style="margin: 10px 0px;">
    <h3 class="subtitle">
      {{ currentDerivation.type_description.toUpperCase() }}:
      {{ derivableTitle }}
      <div style="float: right">
        <el-button
          size="mini"
          type="info"
          @click="updateEnabled = !updateEnabled"
        >
          {{ updateEnabled ? "Terminar" : "Editar datos" }}
        </el-button>
        <el-button
          size="mini"
          type="danger"
          @click="remove()">
          Eliminar
        </el-button>
        <router-link
          :to="{
            name: 'editDerivation',
            params: {
              id: derivation.subject_id,
              derivation_id: derivation.id
            }
          }"
        >
          <el-button
            size="mini"
            type="primary">
            Editar completo
          </el-button>
        </router-link>
      </div>
    </h3>
    <div style="margin-bottom: 10px;">
      <div class="subject-row">
        <div class="label">Tipo:</div>
        <div class="value">
          {{ currentDerivation.type_description || "-----" }}
        </div>
      </div>
    </div>
    <div style="margin-bottom: 10px;">
      <div
        v-if="!updateEnabled"
        class="subject-row">
        <div class="label">Estado:</div>
        <div class="value">
          {{ currentDerivation.state_description || "-----" }}
        </div>
      </div>
      <div
        v-else
        class="subject-row">
        <div class="label">Estado:</div>
        <div class="value">
          <el-select
            v-model="currentDerivation.state"
            clearable
            filterable
            placeholder="Seleccionar estado"
            style="width: 100%;"
            @change="setState"
          >
            <el-option
              v-for="(value, key) in currentDerivation.possible_states"
              :key="key"
              :label="value"
              :value="key"
            >
              {{ value }}
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 10px;">
      <div
        v-if="!updateEnabled"
        class="subject-row">
        <div class="label">Fecha de inicio:</div>
        <div class="value">
          {{ currentDerivation.formatted_date_from || "S/D" }}
        </div>
      </div>
      <div
        v-else
        class="subject-row">
        <div class="label">Fecha de inicio:</div>
        <div class="value">
          <el-date-picker
            v-model="currentDerivation.date_from"
            :clearable="true"
            :format="dateFormat"
            style="width: 100%;"
            name="event_date"
            placeholder="Inicio"
            @change="update()"
          />
        </div>
      </div>
    </div>
    <div style="margin-bottom: 10px;">
      <div
        v-if="!updateEnabled"
        class="subject-row">
        <div class="label">Fecha de proximo control:</div>
        <div class="value">
          {{ currentDerivation.formatted_next_control || "S/D" }}
        </div>
      </div>
      <div
        v-else
        class="subject-row">
        <div class="label">Fecha de proximo control:</div>
        <div class="value">
          <el-date-picker
            v-model="currentDerivation.next_control"
            :clearable="true"
            :format="dateFormat"
            style="width: 100%;"
            name="event_date"
            placeholder="Proximo control"
            @change="update()"
          />
        </div>
      </div>
    </div>
    <div style="margin-bottom: 10px;">
      <div
        v-if="!updateEnabled"
        class="subject-row">
        <div class="label">Motivo:</div>
        <div class="value">{{ currentDerivation.reason || "S/D" }}</div>
      </div>
      <div
        v-else
        class="subject-row">
        <div class="label">Motivo:</div>
        <div class="value">
          <el-input
            v-model="currentDerivation.reason"
            name="name"
            style="width: 100%;"
            placeholder="Motivo de la derivacion"
            @change="update()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clone } from "lodash";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: derivationActions } = namespace("derivation");

export default {
  name: "Derivation",
  props: {
    derivation: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      currentDerivation: {},
      derivable: {},
      dateFormat: "dddd dd, MMMM yyyy",
      updateEnabled: false
    };
  },
  computed: {
    derivableTitle() {
      return `${this.derivable.full_name}
        (${this.derivable.type_desc})`;
    }
  },
  created() {
    this.currentDerivation = clone(this.derivation);
    this.derivable = this.derivation.derivable[
      this.derivation.derivable["type"]
    ];
  },
  methods: {
    ...derivationActions(["updateDerivation"]),
    update() {
      let payload = {
        date_from: this.currentDerivation.date_from,
        next_control: this.currentDerivation.next_control,
        state: this.currentDerivation.state,
        reason: this.currentDerivation.reason
      };
      this.updateDerivation({
        subjectId: this.currentDerivation.subject_id,
        derivationId: this.currentDerivation.id,
        payload: payload
      })
        .then(response => {
          this.currentDerivation = clone(response.derivation);
          this.updateEnabled = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    setState(val) {
      this.currentDerivation.state = val;
      this.currentDerivation.state_description = this.currentDerivation.possible_states[
        this.currentDerivation.state
      ];
      this.update();
    },
    remove() {
      this.$emit("remove", this.currentDerivation.id);
    }
  }
};
</script>

<style lang="css" scoped>
.subtitle {
  background: #f1f1f1;
  padding: 10px 5px;
  text-align: left;
  margin-top: 0px;
}
.subject-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0px;
}
.subject-row .label {
  flex: 1;
}
.subject-row .value {
  flex: 3;
}

</style>
