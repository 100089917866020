<template>
  <div
    v-loading="loading"
    class="page">
    <div v-if="ipp.id">
      <div class="page-header">
        <div class="controls">
          <router-link
            v-is-disabled-for="['efector']"
            :to="{
              name: 'newIntervention',
              params: {
                ipp_id: ipp.id,
                ipp_number: ipp.ipp_number
              }
            }">
            <el-button
              size="small"
              type="primary"
              style="margin-right: 10px">
              <i class="el-icon-plus"></i> Intervencion
            </el-button>
          </router-link>
          <router-link
            :to="{
              name: 'interventions',
              params: {
                ipp_id: ipp.id,
                ipp_number: ipp.ipp_number
              }
            }"
          >
            <el-button
              type="primary"
              size="small"
              style="margin-right: 10px">
              Ver intervenciones
            </el-button>
          </router-link>
          <el-button
            v-is-disabled-for="['efector']"
            type="primary"
            size="small"
            @click="addNewVictimizer()" >
            Agregar Victimario ({{ ipp.victimizers.length }})
          </el-button>
          <el-button
            v-is-disabled-for="['efector']"
            type="primary"
            size="small"
            @click="addNewVictim()" >
            Agregar Victima ({{ ipp.victims.length }})
          </el-button>
        </div>
        <h2>IPP - {{ "Nro." + ipp.ipp_number }}</h2>
      </div>
      <div class="page-content no-bottom">
        <div class="list">
          <div class="ipp-top-info">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-card class="box-card">
                  <el-tooltip
                    :content="'Estado original del IPP: ' + ipp.original_case_state.name"
                    effect="dark"
                    placement="top" >
                    <h4>
                      Estado del caso
                      <a
                        v-is-disabled-for="['efector']"
                        v-if="!editState"
                        style="float: right"
                        @click="editState = true" >
                        <i class="el-icon-edit"></i>
                      </a>
                      <a
                        v-is-disabled-for="['efector']"
                        v-if="editState"
                        style="float: right"
                        @click="editState = false" >
                        Cancelar
                      </a>
                    </h4>
                  </el-tooltip>
                  <p v-if="!editState">
                    {{ ipp.case_state.name }}
                  </p>
                  <div v-else>
                    <el-select
                      v-model="ipp.case_state_id"
                      clearable
                      filterable
                      placeholder="Seleccionar estado"
                      style="width: 100%;"
                      @change="setCase" >
                      <el-option
                        v-for="item in data.case_states"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id" />
                    </el-select>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card class="box-card">
                  <h4>
                    Definición
                    <a
                      v-is-disabled-for="['efector']"
                      v-if="!editDefinition"
                      style="float: right"
                      @click="editDefinition = true" >
                      <i class="el-icon-edit"></i>
                    </a>
                    <a
                      v-is-disabled-for="['efector']"
                      v-if="editDefinition"
                      style="float: right"
                      @click="editDefinition = false" >
                      Cancelar
                    </a>
                  </h4>
                  <p v-if="!editDefinition">
                    {{ ipp.definition }}
                  </p>
                  <div v-else>
                    <el-select
                      v-model="ipp.definition"
                      clearable
                      filterable
                      placeholder="Seleccionar definición"
                      style="width: 100%;"
                      @change="setDefinition" >
                      <el-option
                        v-for="item in data.definitions"
                        :key="item"
                        :label="item"
                        :value="item" />
                    </el-select>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card class="box-card">
                  <h4>
                    Proxima fecha importante
                    <a
                      v-is-disabled-for="['efector']"
                      v-if="!editNextDate"
                      style="float: right"
                      @click="editNextDate = true" >
                      <i class="el-icon-edit"></i>
                    </a>
                    <a
                      v-is-disabled-for="['efector']"
                      v-if="editNextDate"
                      style="float: right"
                      @click="editNextDate = false" >
                      Cancelar
                    </a>
                  </h4>
                  <div v-if="!editNextDate">
                    <p v-if="ipp.next_important_date">
                      {{ ipp.next_important_date | moment("dddd DD [de] MMMM, YYYY") }}
                      <br >
                      <small style="margin: 5px 0px; color: red;">
                        Faltan {{ timeDistance }} dias
                      </small>
                    </p>
                    <p v-else>Sin Fechas</p>
                  </div>
                  <div v-else>
                    <el-date-picker
                      v-model="ipp.next_important_date"
                      :clearable="true"
                      :format="dateFormat"
                      style="width: 100%;"
                      name="event_date"
                      placeholder="Proxima fecha importante"
                      @change="update()" />
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="ipp-content">
            <el-tabs
              v-model="activeName"
              :stretch="true">
              <el-tab-pane
                label="Datos del hecho"
                name="zero">
                <ipp-case-info :ipp="ipp" />
              </el-tab-pane>
              <el-tab-pane
                label="Victimarios"
                name="first">
                <ul>
                  <li
                    v-for="(vict, index) in ipp.victimizers"
                    :key="index">
                    <victimizer
                      :victimizer="vict"
                      :ipp="ipp"
                      @open-modal="openModal(vict)" />
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane
                label="Victimas"
                name="second">
                <ul>
                  <li
                    v-for="(vict, index) in ipp.victims"
                    :key="index">
                    <victim
                      :victim="vict"
                      :ipp="ipp"
                      @open-modal="openModal(vict)" />
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane
                label="Operadores Asignados"
                name="thirth">
                <ipp-case-responsables :ipp="ipp" />
              </el-tab-pane>
              <el-tab-pane
                label="Observaciones"
                name="fourth">
                <ipp-observations :ipp="ipp" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <el-dialog
          v-if="selected.id"
          :visible.sync="isOpen"
          title="Editar Datos Personales"
          @close="selected = {}" >
          <div class="subject-content">
            <el-tabs
              v-model="personTabName"
              :stretch="true">
              <el-tab-pane
                label="Datos Personales"
                name="mfirst">
                <person
                  :person="selected.person"
                  @update-person="data => updatePerson(data)" />
              </el-tab-pane>
              <el-tab-pane
                label="Contactos"
                name="msecond">
                <contacts
                  :person="selected.person"
                  @update-contacts="data => updateContacts(data)" />
              </el-tab-pane>
              <el-tab-pane
                label="Responsables"
                name="mthird">
                <responsables
                  :person="selected.person"
                  @update-responsibles="data => updateResponsibles(data)" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-dialog>
        <el-dialog
          :visible.sync="isVictimOpen"
          title="Agregar nueva victima"
          width="70%" >
          <victim-inputs
            :ipp-id="ipp.id"
            :ipp-form-victims="ipp.victims"
            :request-local="false"
            @add-new-victim="data => addVictimToList(data)"
            @close-victim-modal="closeModal('isVictimOpen')" />
        </el-dialog>
        <el-dialog
          :visible.sync="isVictimizerOpen"
          title="Agregar nuevo victimario"
          width="70%" >
          <victimizer-inputs
            :ipp-id="ipp.id"
            :ipp-form-victimizers="ipp.victimizers"
            :request-local="false"
            @add-new-victimizer="data => addVictimizerToList(data)"
            @close-victimizer-modal="closeModal('isVictimizerOpen')" />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { filter } from "lodash";
import Victimizer from "./Sections/Victimizer";
import Victim from "./Sections/Victim";
import VictimizerInputs from "./Form/VictimizerInputs";
import VictimInputs from "./Form/VictimInputs";
import IppCaseResponsables from "./Sections/IppCaseResponsables";
import IppObservations from "./Sections/IppObservations";
import IppCaseInfo from "./Sections/IppCaseInfo";
import Contacts from "../Subject/Contacts";
import Responsables from "../Subject/Responsables";
import Person from "../Subject/Person";
import * as moment from "moment";

import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: ippActions, mapGetters: ippGetters } = namespace("ipp");

export default {
  name: "Ipp",
  components: {
    Victimizer,
    Victim,
    IppCaseResponsables,
    IppObservations,
    IppCaseInfo,
    Contacts,
    Responsables,
    Person,
    VictimizerInputs,
    VictimInputs
  },
  data() {
    return {
      loading: false,
      ipp: {},
      activeName: "zero",
      personTabName: "mfirst",
      editAssigned: false,
      selected: {},
      isOpen: false,
      isVictimizerOpen: false,
      isVictimOpen: false,
      editDefinition: false,
      editState: false,
      editNextDate: false,
      dateFormat: "dddd dd, MMMM yyyy"
    };
  },
  computed: {
    ...ippGetters(["data"]),
    victimizers() {
      if (this.ipp) {
        return filter(this.ipp.subjects, v => {
          return v.role === "victimizer";
        });
      } else {
        return [];
      }
    },
    victims() {
      if (this.ipp) {
        return filter(this.ipp.subjects, s => {
          return s.role === "victims";
        });
      } else {
        return [];
      }
    },
    timeDistance() {
      if (this.ipp.next_important_date) {
        let now = new Date();
        let date = moment(this.ipp.next_important_date).valueOf();
        return Math.round((date - now) / (1000 * 3600 * 24));
      } else {
        return null;
      }
    }
  },
  watch: {
    "$route.params.id": function() {
      this.loadIpp();
    }
  },
  created() {
    this.loadIpp();
    this.loadIppData();
  },
  methods: {
    ...ippActions(["fetchIpp", "updateIpp", "loadIppData"]),
    filter: filter,
    loadIpp() {
      this.loading = true;
      this.fetchIpp({ ippId: this.$route.params.id })
        .then(response => {
          this.ipp = response.ipp_case;
        })
        .catch(error => {
          console.log("Error en loadIpp", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openResponsablesModal() {
      this.editAssigned = true;
      this.modalComponent = IppCaseResponsables;
    },
    openModal(vict) {
      this.selected = vict;
      this.isOpen = true;
    },
    updatePerson(data) {
      this.selected.person = data;
    },
    updateContacts(data) {
      this.selected.person.contacts = data;
    },
    updateResponsibles(data) {
      this.selected.person.responsibles = data;
    },
    printPerson() {
      console.log("Probando un emit");
    },
    addNewVictimizer() {
      this.isVictimizerOpen = true;
    },
    addNewVictim() {
      this.isVictimOpen = true;
    },
    addVictimizerToList(data) {
      this.isVictimizerOpen = false;
      this.ipp = data.ipp_case;
    },
    addVictimToList(data) {
      this.isVictimOpen = false;
      this.ipp = data.ipp_case;
    },
    closeModal(modal) {
      this[modal] = false;
    },
    update() {
      let params = {
        case_state_id: this.ipp.case_state.id,
        definition: this.ipp.definition,
        next_important_date: this.ipp.next_important_date
      };
      this.updateIpp({ ippId: this.ipp.id, data: params })
        .then(response => {
          this.ipp_case = response.ipp_case;
          this.editState = false;
          this.editDefinition = false;
          this.editNextDate = false;
        })
        .catch(error => {
          console.log("Error update ipp", error);
        });
    },
    setCase(val) {
      this.ipp.case_state = this.data.case_states.find(s => val === s.id);
      this.update();
    },
    setDefinition(val) {
      this.ipp.definition = val;
      this.update();
    }
  }
};
</script>

<style lang="css">
.el-tabs__item {
  font-size: 16px;
}
#ipps-data {
  background: #fff;
  height: 100%;
}
.ipp-top-info {
  margin: 10px 0px;
  border-bottom: solid 3px #eee;
  padding-bottom: 15px;
}
.ipp-top-info .box-card {
  height: 130px;
  text-align: center;
}
.ipp-top-info .box-card h4 {
  margin-top: 0px;
}
.ipp-content ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.data {
  padding: 0px 20px;
}
.box-content {
  overflow: scroll;
  height: 100%;
}
.ipp-responsable {
  padding: 5px 0px;
  border-bottom: solid 1px #eee;
  margin-bottom: 5px;
  font-size: 15px;
}
.subject-content {
  .edit-buttons {
    text-align: right;
    font-size: 0.8rem;
    margin: 10px 0px;
    border-bottom: solid 1px #ddd;
    padding-bottom: 20px;
    a.save {
      color: #f56c6c;
      margin-left: 10px;
    }
    a.cancel,
    a.edit {
      color: #999;
    }
  }
  .person-data,
  .contacts-data {
    margin-bottom: 20px;
  }
  a {
    cursor: pointer;
  }
}
</style>
