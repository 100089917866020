<template>
  <el-form>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form-item>
          <div :class="['el-input', newVictim.noDNI ? 'is-disabled' : '']">
            <the-mask
              v-model="newVictim.document_number"
              :mask="['##.###.###', '#.###.###']"
              :disabled="newVictim.noDNI"
              suffix-icon="icono-arg-dni"
              name="document_number"
              class="el-input__inner"
              style="width: 100%;"
              placeholder="Buscar por nro de DNI" />
          </div>
          <div>
            <el-checkbox
              v-model="newVictim.noDNI"
              label="Sin DNI"
              @change="cleanField('document_number')" />
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-button
          :disabled="newVictim.noDNI"
          style="width: 100%"
          @click="validateDNI()" >
          <i class="el-icon-check" ></i>
        </el-button>
      </el-col>
      <el-col :span="6">
        <el-form-item style="width: 100%;">
          <el-radio-group
            v-model="newVictim.gender"
            :disabled="!dniPresent"
            style="width: 100%;" >
            <el-radio-button label="Fem" />
            <el-radio-button label="Masc" />
            <el-radio-button label="Otro" />
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item style="width: 100%;">
          <el-date-picker
            v-model="newVictim.birth_date"
            :clearable="true"
            :disabled="!dniPresent"
            :format="dateFormat"
            style="width: 100%;"
            name="birth_date"
            placeholder="Fecha de nacimiento"
            @change="checkMinor()" />
          <div>
            <el-switch
              v-model="newVictim.minor"
              active-text="Menor"
              inactive-text="Adulto"
              @change="checkMinor()" />
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item>
          <el-input
            v-model="newVictim.firstname"
            :disabled="!dniPresent"
            name="name"
            style="width: 100%;"
            placeholder="Nombre" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <el-input
            v-model="newVictim.lastname"
            :disabled="!dniPresent"
            name="last_name"
            style="width: 100%;"
            placeholder="Apellido" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="20">
        <el-form-item>
          <div :class="['el-input', isDisabled ? 'is-disabled' : '']">
            <vue-google-autocomplete
              id="map"
              ref="address"
              :country="['ar', 'br']"
              :readonly="isDisabled"
              :disabled="isDisabled"
              name="address"
              classname="el-input__inner"
              placeholder="Direccion completa"
              autocomplete="false"
              style="width: 100%;"
              @placechanged="getAddressData" />
            <div>
              <el-checkbox
                v-model="newVictim.noAddress"
                label="Direccion desconocida"
                @change="cleanField('address')" />
            </div>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-button
          :disabled="newVictim.noAddress || !newVictim.address"
          style="width: 100%;"
          @click="validateAddress()" >
          <i class="el-icon-check"></i>
        </el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          :disabled="!dataValidated"
          style="width: 100%;"
          @click="checkNewPerson()">
          <i class="el-icon-plus"></i>
        </el-button>
      </el-col>
    </el-row>
    <el-row
      v-if="!requestLocal"
      :gutter="20">
      <el-col :span="24">
        <el-form-item label="Tipos de victimario">
          <el-checkbox-button
            v-model="newVictim.legal"
            type="primary">
            Legal
          </el-checkbox-button>
          <el-checkbox-button
            v-model="newVictim.restorative"
            type="primary">
            Restaurativo
          </el-checkbox-button>
          <el-checkbox-button
            v-model="newVictim.conflictual"
            type="primary">
            Conflictual
          </el-checkbox-button>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      v-if="!requestLocal"
      :gutter="20">
      <el-col
        :span="24"
        style="text-align: right;">
        <el-button
          :disabled="!enableSave"
          type="primary"
          @click="updateIpp()">
          Guardar
        </el-button>
        <el-button @click="closeModal()">
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { extend, clone, forEach } from "lodash";
import VueGoogleAutocomplete from "../../Shared/VueGoogleAutocomplete";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapActions: ippActions } = namespace("ipp");
export default {
  name: "VictimizerInputs",
  components: { VueGoogleAutocomplete },
  props: {
    ippFormVictims: {
      required: true,
      type: Array
    },
    requestLocal: {
      required: false,
      type: Boolean,
      default: () => {
        return false;
      }
    },
    ippId: {
      required: false,
      type: Number,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      loading: false,
      dniError: "",
      dateFormat: "dddd dd, MMMM yyyy",
      enableSave: false,
      newVictim: {
        firstname: "",
        lastname: "",
        document_number: "",
        birth_date: "",
        gender: "",
        address: "",
        coordinates: "",
        role: "victim",
        noAddress: false,
        noDNI: false,
        dniValidated: false,
        addressValidated: false,
        minor: null,
        legal: false,
        restorative: false,
        conflictual: false
      }
    };
  },
  computed: {
    dataValidated() {
      let hasName =
        this.newVictim.firstname.trim() !== "" &&
        this.newVictim.lastname.trim() !== "";
      let dniValid = this.newVictim.noDNI || this.newVictim.dniValidated;
      let addressValid =
        this.newVictim.noAddress || this.newVictim.addressValidated;

      return hasName && dniValid && addressValid;
    },
    dniPresent() {
      return (
        (this.newVictim.document_number && this.newVictim.dniValidated) ||
        this.newVictim.noDNI
      );
    },
    isDisabled() {
      return this.newVictim.noAddress || !this.dniPresent;
    }
  },
  methods: {
    ...ippActions([
      "checkDNI",
      "addPerson",
      "createPerson",
      "updateSubjectIpp"
    ]),
    cleanField(field) {
      this.newVictim[field] = "";
    },
    validateDNI() {
      if (this.isValidFormat()) {
        if (this.isNotSelected()) {
          this.checkDNI({ dni: this.newVictim.document_number })
            .then(response => {
              extend(this.newVictim, response.person);
              if (this.requestLocal) {
                this.addVictimizer();
              } else {
                this.enableSave = true;
              }
            })
            .catch(error => {
              console.log("error en checkDNI", error);
              this.newVictim.dniValidated = true;
            });
        } else {
          this.$message({
            type: "error",
            message: "Victimario ya seleccionado"
          });
        }
      } else {
        this.$message({
          type: "error",
          message: this.dniError
        });
      }
    },
    validateAddress() {
      this.newVictim.addressValidated =
        this.newVictim.address !== "" && this.newVictim.coordinates !== "";
    },
    checkMinor() {
      if (
        (this.newVictim.birth_date != null) &
        (this.newVictim.birth_date != "")
      ) {
        this.newVictim.minor = this.getAge(this.newVictim.birth_date) < 18;
      }
    },
    addVictim() {
      this.savePersonToIpp();
    },
    savePersonToIpp() {
      this.addPerson({
        person: this.newVictim,
        role: "victims",
        minor: this.newVictim.minor
      })
        .then(() => {
          let vict = clone(this.newVictim);
          this.$message({
            type: "success",
            message: `Se agrego a ${vict.firstname} ${vict.lastname}`
          });
          this.resetForm();
        })
        .catch(error => {
          console.log("error en addPerson", error);
        });
    },
    checkNewPerson() {
      if (this.requestLocal) {
        this.savePersonToIpp();
      } else {
        this.createPerson({
          person: this.newVictim,
          role: "victims",
          minor: this.newVictim.minor
        })
          .then(response => {
            this.enableSave = true;
            this.newVictim = clone(response);
            this.$message({
              type: "success",
              message: `Se agrego al sistema
                ${this.newVictim.firstname}
                ${this.newVictim.lastname}
              `
            });
          })
          .catch(error => {
            console.log("error en addPerson", error);
          });
      }
    },
    updateIpp() {
      let subject = {
        person_id: this.newVictim.id,
        role: "victim",
        minor: this.newVictim.minor,
        legal: this.newVictim.legal,
        restorative: this.newVictim.restorative,
        conflictual: this.newVictim.conflictual
      };

      console.log("Subject", subject);
      this.updateSubjectIpp({
        ippId: this.ippId,
        subject: subject
      })
        .then(response => {
          this.resetForm();
          this.$emit("add-new-victim", response.data);
        })
        .catch(error => {
          forEach(error.response.data, (message, index) => {
            let time = Number(index) * 600;
            setTimeout(() => {
              this.$notify.error({
                title: "Error",
                message: message,
                duration: 5000
              });
            }, time);
          });
        });
    },
    isValidFormat() {
      if (!this.newVictim.document_number) {
        this.dniError = "No hay DNI";
        return false;
      }
      let leng = this.newVictim.document_number.length;
      if (leng < 7) {
        this.dniError = "El formato es invalido";
        return false;
      }
      return true;
    },
    resetForm() {
      this.newVictim = {
        id: "",
        firstname: "",
        lastname: "",
        document_number: "",
        gender: "",
        address: "",
        role: "victimizer",
        noAddress: false,
        noDNI: false,
        dniValidated: false,
        addressValidated: false,
        minor: null
      };
    },
    isNotSelected() {
      let p = this.ippFormVictims.find(
        v => v.person.document_number == this.newVictim.document_number
      );
      if (p) {
        // console.log("No p");
        this.dniError = "El dni ingresado ya esta seleccionado";
        return false;
      }
      return true;
    },
    getAge(dateString) {
      let today = new Date();
      let birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    closeModal() {
      this.resetForm();
      this.$emit("close-victim-modal");
    },
    getAddressData: function(addressData, placeResultData) {
      // console.log(addressData);
      // console.log(placeResultData);
      this.newVictim.address = placeResultData.formatted_address;
      this.newVictim.coordinates = `POINT(${addressData.longitude} ${addressData.latitude})`;

      this.$nextTick(() => {
        this.$refs.address.update(this.newVictim.address);
      });
    }
  }
};
</script>
