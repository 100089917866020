<template>
  <div class="ipp-form-container">
    <div class="ipp-step-inner">
      <el-button
        :disabled="!valid"
        size="small"
        type="primary"
        @click="goNext()"
        style="float: right; top: 10px;">
        Siguiente
        <fw-icon icon="chevron-right" />
      </el-button>
      <h3>Origen y Estado</h3>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item>
            <span slot="label">Origen</span>
            <el-select
              v-model="ipp.origin_id"
              clearable
              filterable
              placeholder="Seleccionar origen"
              style="width: 100%;"
              @change="setOrigin"
            >
              <el-option
                v-for="item in data.origins"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.city
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <span slot="label">Nro de IPP</span>
            <el-input
              v-mask="'##-##-####-##'"
              v-model="ipp.ipp_number"
              name="number"
              type="tel"
              style="width: 100%;"
              placeholder="Numero de IPP"
              @blur="checkIppNumber"
            />
          </el-form-item>
          <el-alert
            v-if="ippNumberError"
            :title="ippNumberError"
            :closable="false"
            type="error"
          />
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <span slot="label">Estado</span>
            <el-select
              v-model="ipp.case_state_id"
              clearable
              filterable
              placeholder="Seleccionar estado"
              style="width: 100%;"
              @change="setCase"
            >
              <el-option
                v-for="item in data.case_states"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <h3>Datos del hecho</h3>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item>
            <span slot="label">Dirección</span>
            <div class="el-input">
              <vue-google-autocomplete
                id="map"
                ref="address"
                :country="['ar', 'br']"
                class="el-input__inner"
                placeholder="Direccion del hecho"
                autocomplete="false"
                style="width: 100%;"
                @placechanged="getAddressData"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item>
            <span slot="label">Fecha del Hecho</span>
            <el-date-picker
              v-model="ipp.event_date"
              :clearable="true"
              :disabled="ipp.eventDateDisabled"
              :format="dateFormat"
              style="width: 100%;"
              name="event_date"
              placeholder="Fecha del hecho"
            />
            <div>
              <el-checkbox
                v-model="ipp.eventDateDisabled"
                label="Fecha desconocida"
                @change="cleanData('eventDate')"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <span slot="label">Hora del hecho</span>
            <el-time-select
              v-model="ipp.event_hour"
              :clearable="true"
              :disabled="ipp.eventHourDisabled"
              :picker-options="pickerOptions"
              style="width: 100%;"
              name="event_hour"
              placeholder="Hora del hecho"
            />
            <div>
              <el-checkbox
                v-model="ipp.eventHourDisabled"
                label="Hora desconocida"
                @change="cleanData('eventHour')"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <span slot="label">Fecha de la denuncia</span>
            <el-date-picker
              v-model="ipp.report_date"
              :clearable="true"
              :disabled="ipp.reportDateDisabled"
              :format="dateFormat"
              :picker-options="pickerReportOptions"
              style="width: 100%;"
              name="report_date"
              placeholder="Fecha de la denuncia"
            />
            <div>
              <el-checkbox
                v-model="ipp.reportDateDisabled"
                label="Fecha desconocida"
                @change="cleanData('reportDate')"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { extend } from "lodash";
import VueGoogleAutocomplete from "../../Shared/VueGoogleAutocomplete";
import { createNamespacedHelpers as namespace } from "vuex";
const { mapGetters: ippGetters, mapActions: ippActions } = namespace("ipp");

export default {
  name: "BasicData",
  components: { VueGoogleAutocomplete },
  data() {
    return {
      dateFormat: "dddd dd, MMMM yyyy",
      pickerOptions: {
        start: "00:00",
        step: "00:15",
        end: "23:59"
      },
      pickerReportOptions: {
        disabledDate: time => {
          return time.getTime() < this.ipp.event_date.getTime();
        }
      },
      ipp: {
        ipp_number: "",
        event_date: "",
        event_hour: "",
        report_date: "",
        coordinates: "",
        eventDateDisabled: false,
        eventHourDisabled: false,
        reportDateDisabled: false,
        origin_id: "",
        origin: {},
        case_state_id: "",
        case_state: {}
      },
      ippNumberError: null
    };
  },
  computed: {
    ...ippGetters(["ippFormBase", "data"]),
    valid() {
      return (
        this.ipp.ipp_number &&
        !this.ippNumberError &&
        this.ipp.origin_id &&
        this.ipp.case_state_id &&
        (this.ipp.event_date || this.ipp.eventDateDisabled) &&
        (this.ipp.event_hour || this.ipp.eventHourDisabled) &&
        (this.ipp.report_date || this.ipp.reportDateDisabled)
      );
    }
  },
  created() {
    this.parseFromStorage();
  },
  methods: {
    ...ippActions(["setIppBase", "fetchIpps"]),
    parseFromStorage() {
      extend(this.ipp, this.ippFormBase);
      this.$nextTick(() => {
        this.$refs.address.update(this.ipp.event_address);
      });
    },
    goNext() {
      if (this.valid) {
        this.setIppBase(this.ipp);
        this.$emit("next", 1);
      }
    },
    checkIppNumber() {
      if (this.ipp.ipp_number == "") {
        this.ippNumberError = null;
        return;
      }
      this.loading = true;
      this.fetchIpps({
        field: "ipp",
        criteria: this.ipp.ipp_number,
        page: 1,
        pageSize: 1
      })
        .then(response => {
          this.loading = false;
          if (response.meta.total_entries > 0) {
            this.ippNumberError = "El nro de ipp ingresado ya existe!";
          } else {
            this.ippNumberError = null;
          }
        })
        .catch(error => {
          console.log("error en fetchIpps", error);
        });
    },
    cleanData(field) {
      if (field == "reportDate") {
        this.ipp.report_date = "";
      } else if (field == "eventDate") {
        this.ipp.event_date = "";
      } else if (field == "eventHour") {
        this.ipp.event_hour = "";
      }
    },
    setOrigin(val) {
      this.ipp.origin = this.data.origins.find(s => val === s.id);
    },
    setCase(val) {
      this.ipp.case_state = this.data.case_states.find(s => val === s.id);
    },
    minDateSet() {
      return this.event_date <= this.report_date;
    },
    getAddressData: function(addressData, placeResultData) {
      console.log(addressData);
      console.log(placeResultData);
      this.ipp.event_address = placeResultData.formatted_address;
      this.ipp.coordinates = `POINT(${addressData.longitude} ${addressData.latitude})`;

      this.$nextTick(() => {
        this.$refs.address.update(this.ipp.event_address);
      });
    }
  }
};
</script>
